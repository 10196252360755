<div class="sp-p-4 sp-w-full">
  <div class="sp-text-3xl sp-mb-3 sp-text-sp-blue">{{ data.title }}</div>
  <mat-divider></mat-divider>
  <div class="sp-w-full sp-mt-4">
    @if (loaded) {
      <form class="sp-w-full" [formGroup]="formGroup">
        <div class="sp-flex sp-flex-row sp-mb-4 sp-w-full">
          <div class="sp-w-1/2 sp-mr-4">

            <div class="sp-text-2xl sp-mb-3 sp-text-sp-green ng-tns-c1914664829-3">User Details</div>

            <mat-form-field class="sp-w-full sp-mb-2" appearance="outline">
              <mat-label>First Name</mat-label>
              <input formControlName="first_name" type="text" matInput>
              <mat-hint class="animate__animated animate__fadeIn"
                        *ngIf="firstNameField?.invalid && (firstNameField?.dirty || firstNameField?.touched)">
                <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                       *ngIf="firstNameField?.errors?.['required']">
                  First name is required.
                </small>
                <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                       *ngIf="firstNameField?.errors?.['maxlength']">
                  Name cannot be greater
                  than {{ firstNameField?.errors ? firstNameField?.errors['maxlength'].requiredLength : 0 }} characters,
                  (Current
                  length: {{ firstNameField?.errors ? firstNameField?.errors['maxlength'].actualLength : 0 }})
                </small>
                <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                       *ngIf="firstNameField?.errors?.['minlength']">
                  Name cannot be less
                  than {{ firstNameField?.errors ? firstNameField?.errors['minlength'].requiredLength : 0 }} characters,
                  (Current
                  length: {{ firstNameField?.errors ? firstNameField?.errors['minlength'].actualLength : 0 }})
                </small>
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="sp-w-full sp-mb-2" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input formControlName="last_name" type="text" matInput>
              <mat-hint class="animate__animated animate__fadeIn"
                        *ngIf="lastNameField?.invalid && (lastNameField?.dirty || lastNameField?.touched)">
                <small class="small animated red fadeIn faster d-block mt-2 mb-2"
                       *ngIf="lastNameField?.errors?.['required']">
                  Last name is required.
                </small>
                <small class="small animated red fadeIn faster d-block mt-2 mb-2"
                       *ngIf="lastNameField?.errors?.['maxlength']">
                  Name cannot be greater
                  than {{ lastNameField?.errors ? lastNameField?.errors['maxlength'].requiredLength : 0 }} characters,
                  (Current
                  length: {{ lastNameField?.errors ? lastNameField?.errors['maxlength'].actualLength : 0 }})
                </small>
                <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                       *ngIf="lastNameField?.errors?.['minlength']">
                  Name cannot be less
                  than {{ lastNameField?.errors ? lastNameField?.errors['minlength'].requiredLength : 0 }}
                  characters, (Current
                  length: {{ lastNameField?.errors ? lastNameField?.errors['minlength'].actualLength : 0 }})
                </small>
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="sp-w-full sp-mb-2" appearance="outline">
              <mat-label>Email</mat-label>
              <input formControlName="email" type="email" matInput>
              <mat-hint class="animate__animated animate__fadeIn"
                        *ngIf="emailField?.invalid && (emailField?.dirty || emailField?.touched)">
                <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                       *ngIf="emailField?.errors?.['required']">
                  Email is required.
                </small>
                <small class="small animated fadeIn faster red d-block mt-2 mb-2" *ngIf="emailField?.errors?.['email']">
                  Email needs to be a valid email address
                </small>
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="sp-w-full sp-mb-2" appearance="outline">
              <mat-label>Qualifications</mat-label>
              <input formControlName="qualifications" type="text" matInput>
            </mat-form-field>
          </div>
          <div class="sp-w-1/2 sp-ml-4">

            <div class="sp-text-2xl sp-mb-3 sp-text-sp-green ng-tns-c1914664829-3">Permissions</div>

            <div [matTooltip]="currentUser.id == model.id ? 'You cannot set the administration status on yourself, if you wish to remove administration status from yourself, ask another administrator to do this for you.' : ''" class="sp-flex sp-flex-row sp-w-full sp-pt-4 sp-mb-2 sp-h-[78px]">
              <mat-slide-toggle formControlName="admin">
                <span class="sp-text-stone-600 sp-ml-2">
                  Is Administrator
                </span>
              </mat-slide-toggle>
            </div>
            <div class="sp-mb-2">
              <mat-form-field [matTooltip]="namedSurveyorCount <= 1 && model.permission == UserRoles.NamedSurveyor ? 'This user is the only named surveyor for this company! You must have an account that is a named surveyor so this can NOT be changed right now!' : ''" class="sp-w-full" appearance="outline">
                <mat-label>Role</mat-label>
                <mat-select formControlName="permission">
                  <mat-option [value]="UserRoles.NamedSurveyor">
                    Named Surveyor
                  </mat-option>
                  <mat-option [value]="UserRoles.None">
                    Coordinator
                  </mat-option>
                  <mat-option [value]="UserRoles.Surveyor">
                    Surveyor
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="red">
              @if (model.linked_to_job) {
                This user is currently linked to jobs as a {{ model.permission == UserRoles.NamedSurveyor ? 'Named Surveyor' : 'Surveyor' }}. Changing their role will result in the user being unlinked from all jobs.
              }
            </div>
          </div>
        </div>
        <div>
          <div class="sp-text-xl sp-mb-3 sp-text-sp-green">User Brands</div>
          <p class="sp-text-slate-600 !sp-my-4">
            Select which brands you want this user to be a part of. To enable reminders: Click the branding chip and the
            user will receive emails on all job updates within that brand.
          </p>
          @if(model.brands.length == 0) {
            <p class="sp-text-red-600 !sp-my-4">
              Whilst not a strict requirement, it is recommended a user always have access to at least one brand.
            </p>
          }
          <mat-form-field floatLabel="always" appearance="outline" class="sp-w-full sp-mt-4">
            <mat-label>User Brands</mat-label>
            <mat-chip-grid #chipGrid aria-label="Brand selection">
              @for (brand of this.model.brands; track brand) {
                <mat-chip-option [value]="brand" (selectionChange)="updateEmailStatus($event)"
                                 [selected]="brand.updates" #chip
                                 [matTooltip]="chip.selected ? 'Email Reminders: The user will receive emails on all job updates within this brand.' : ''"
                                 (removed)="remove(brand.id)">
                  {{ getName(brand.id) }}
                  <button matChipRemove [attr.aria-label]="'remove ' + brand">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
              }
            </mat-chip-grid>
            <input placeholder="Find Brand..." #brandInput [formControl]="brandCtrl"
                   [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"/>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              @for (brand of filteredBrands | async; track brand) {
                <mat-option [value]="brand">{{ brand.name }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="sp-flex sp-flex-row sp-items-center sp-justify-end">
          <sp-button class="sp-mr-2" icon="fa-solid fa-times" (sp-click)="cancel()" colour="red">
            Cancel
          </sp-button>
          <sp-button class="sp-ml-2" icon="fa-solid fa-check" (sp-click)="save()" [disabled]="formGroup.invalid"
                     colour="green">
            Save
          </sp-button>
        </div>
      </form>
    }
  </div>
</div>
