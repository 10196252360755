@if (model != null) {
  <form [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}" [formGroup]="formGroup" #form="ngForm">
    <div class="sp-w-full sp-flex sp-flex-row sp-items-stretch">
      <div class="sp-w-1/2 sp-mr-8">
        <div class="sp-w-full sp-mb-2">
          <div class="sp-text-2xl sp-mb-3 sp-text-sp-blue">Account Details</div>
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Company Name
            </mat-label>
            <input formControlName="company_name" type="text" matInput>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Address Line 1
            </mat-label>
            <input formControlName="address_line_1" type="text" matInput>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Address Line 2
            </mat-label>
            <input formControlName="address_line_2" type="text" matInput>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Town/City
            </mat-label>
            <input formControlName="city" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn"
                      *ngIf="cityField?.invalid && (cityField?.dirty || cityField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="cityField?.errors?.['maxlength']">
                Town/City cannot be greater
                than {{ cityField?.errors ? cityField?.errors['maxlength'].requiredLength : 0 }}
                characters, (Current
                length: {{ cityField?.errors ? cityField?.errors['maxlength'].actualLength : 0 }})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              County
            </mat-label>
            <input formControlName="county" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn"
                      *ngIf="countyField?.invalid && (countyField?.dirty || countyField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="countyField?.errors?.['maxlength']">
                County cannot be greater
                than {{ countyField?.errors ? countyField?.errors['maxlength'].requiredLength : 0 }} characters,
                (Current
                length: {{ countyField?.errors ? countyField?.errors['maxlength'].actualLength : 0 }})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Postcode
            </mat-label>
            <input formControlName="post_code" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn"
                      *ngIf="postcodeField && postcodeField.invalid && (postcodeField.dirty || postcodeField.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="postcodeField.errors['maxlength']">
                Post code cannot be greater
                than {{ postcodeField.errors['maxlength'].requiredLength }} characters,
                (Current
                length: {{ postcodeField.errors['maxlength'].actualLength }})
              </small>
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="postcodeField.errors?.pattern">
                Please enter a valid PostCode
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-4">
          <mat-form-field subscriptSizing="dynamic" class="sp-w-full" appearance="outline">
            <mat-label>
              Contact Number
            </mat-label>
            <input formControlName="contact_number" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn"
                      *ngIf="contactNumberField?.invalid && (contactNumberField?.dirty || contactNumberField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="contactNumberField?.errors?.['maxlength']">
                Contact number cannot be greater
                than {{ contactNumberField?.errors ? contactNumberField?.errors['maxlength'].requiredLength : 0 }}
                characters, (Current
                length: {{ contactNumberField?.errors ? contactNumberField?.errors['maxlength'].actualLength : 0 }})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="sp-w-1/2 sp-ml-8 sp-flex sp-flex-col">
        <div class="sp-w-full sp-mb-2">
          <div class="sp-text-2xl sp-mb-3 sp-text-sp-blue">Professional Body Memberships</div>
          <div class="sp-p-4 sp-rounded sp-bg-yellow-200">
            <p class="!sp-my-0 sp-text-yellow-800">
              Membership of a professional body provides users with access to their relevant party wall templates. If you are unable to select a professional body please contact us with details of the relevant membership and we will enable it for you.
            </p>
          </div>

          <mat-selection-list disabled class="sp-overflow-hidden" role="list">
            @for(item of groups; track item) {
              <mat-list-option [selected]="item.selected" role="listitem">{{item.name}}</mat-list-option>
            }
          </mat-selection-list>
        </div>
        <sp-button [disabled]="formGroup.invalid" (sp-click)="save()" class="sp-mt-auto sp-ml-auto sp-mb-4" icon="fa-solid fa-check" colour="green">
          Save
        </sp-button>
      </div>
    </div>
  </form>
}

