<app-wrapper-container>
  <survpal-window>
    @if (!loading) {
      <form class="sp-w-full" [formGroup]="formGroup">
        <div class="sp-px-4 sp-pt-4">
          <div class="sp-w-full" [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}">
            <div class="sp-flex sp-flex-row sp-w-full">
              <div class="sp-w-full sp-mr-4">
                <div class="sp-text-2xl sp-text-sp-blue sp-mb-4">My Profile</div>
                <div class="sp-w-full sp-mb-2">
                  <mat-form-field class="sp-w-full" appearance="outline">
                    <mat-label>
                      First Name
                    </mat-label>
                    <input formControlName="first_name" type="text" matInput>
                    <mat-hint class="animate__animated animate__fadeIn"
                              *ngIf="firstNameField?.invalid && (firstNameField?.dirty || firstNameField?.touched)">
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="firstNameField?.errors?.['required']">
                        First name is required.
                      </small>
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="firstNameField?.errors?.['maxlength']">
                        First name cannot be greater
                        than {{ firstNameField?.errors ? firstNameField?.errors['maxlength'].requiredLength : 0 }}
                        characters,
                        (Current
                        length: {{ firstNameField?.errors ? firstNameField?.errors['maxlength'].actualLength : 0 }})
                      </small>
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="firstNameField?.errors?.['minlength']">
                        First name cannot be less
                        than {{ firstNameField?.errors ? firstNameField?.errors['minlength'].requiredLength : 0 }}
                        characters,
                        (Current
                        length: {{ firstNameField?.errors ? firstNameField?.errors['minlength'].actualLength : 0 }})
                      </small>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="sp-w-full sp-mb-2">
                  <mat-form-field class="sp-w-full" appearance="outline">
                    <mat-label>
                      Last Name
                    </mat-label>
                    <input formControlName="last_name" type="text" matInput>
                    <mat-hint class="animate__animated animate__fadeIn"
                              *ngIf="lastNameField?.invalid && (lastNameField?.dirty || lastNameField?.touched)">
                      <small class="small animated red fadeIn faster d-block mt-2 mb-2"
                             *ngIf="lastNameField?.errors?.['required']">
                        Last name is required.
                      </small>
                      <small class="small animated red fadeIn faster d-block mt-2 mb-2"
                             *ngIf="lastNameField?.errors?.['maxlength']">
                        Last name cannot be greater
                        than {{ lastNameField?.errors ? lastNameField?.errors['maxlength'].requiredLength : 0 }}
                        characters,
                        (Current
                        length: {{ lastNameField?.errors ? lastNameField?.errors['maxlength'].actualLength : 0 }})
                      </small>
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="lastNameField?.errors?.['minlength']">
                        Last name cannot be less
                        than {{ lastNameField?.errors ? lastNameField?.errors['minlength'].requiredLength : 0 }}
                        characters, (Current
                        length: {{ lastNameField?.errors ? lastNameField?.errors['minlength'].actualLength : 0 }})
                      </small>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="sp-w-full sp-mb-2">
                  <mat-form-field class="sp-w-full" appearance="outline">
                    <mat-label>
                      Mobile Number
                    </mat-label>
                    <input formControlName="mobile" type="text" matInput>
                    <mat-hint class="animate__animated animate__fadeIn"
                              *ngIf="mobileNumberField?.invalid && (mobileNumberField?.dirty || mobileNumberField?.touched)">
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="mobileNumberField?.errors?.['maxlength']">
                        Mobile number cannot be greater
                        than {{ mobileNumberField?.errors ? mobileNumberField?.errors['maxlength'].requiredLength : 0 }}
                        characters, (Current
                        length: {{ mobileNumberField?.errors ? mobileNumberField?.errors['maxlength'].actualLength : 0 }}
                        )
                      </small>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="sp-w-full sp-mb-2">
                  <mat-form-field class="sp-w-full" appearance="outline">
                    <mat-label>
                      Telephone Number
                    </mat-label>
                    <input formControlName="telephone" type="text" matInput>
                    <mat-hint class="animate__animated animate__fadeIn"
                              *ngIf="telephoneNumberField?.invalid && (telephoneNumberField?.dirty || telephoneNumberField?.touched)">
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="telephoneNumberField?.errors?.['maxlength']">
                        Telephone number cannot be greater
                        than {{ telephoneNumberField?.errors ? telephoneNumberField?.errors['maxlength'].requiredLength : 0 }}
                        characters, (Current
                        length: {{ telephoneNumberField?.errors ? telephoneNumberField?.errors['maxlength'].actualLength : 0 }}
                        )
                      </small>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="sp-w-full sp-mb-2">
                  <mat-form-field class="sp-w-full" appearance="outline">
                    <mat-label>
                      Email Address
                    </mat-label>
                    <input formControlName="email" type="email" matInput>
                    <mat-hint class="animate__animated animate__fadeIn"
                              *ngIf="emailField?.invalid && (emailField?.dirty || emailField?.touched)">
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="emailField?.errors?.['required']">
                        Email is required.
                      </small>
                      <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                             *ngIf="emailField?.errors?.['email']">
                        Email needs to be a valid email address
                      </small>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="sp-w-full sp-mb-2">
                  <mat-form-field class="sp-w-full" appearance="outline">
                    <mat-label>
                      Qualifications
                    </mat-label>
                    <input formControlName="qualifications" type="text" matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="sp-w-full sp-ml-4">
                <div class="sp-text-2xl sp-text-sp-blue sp-mb-4">Kanban Board Settings</div>
                <div class="sp-w-full sp-mb-2 sp-flex sp-flex-row sp-items-center">
                  <div class="sp-flex-1 sp-mr-4">
                    <mat-form-field subscriptSizing="fixed" class="sp-w-full" appearance="outline">
                      <mat-label>
                        Initials
                      </mat-label>
                      <input formControlName="initials" type="text" matInput>
                      <mat-hint class="animate__animated animate__fadeIn"
                                *ngIf="initialsField?.invalid && (initialsField?.dirty || initialsField?.touched)">
                        <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                               *ngIf="initialsField?.errors?.['maxlength']">
                          Initials cannot be greater
                          than {{ initialsField?.errors ? initialsField?.errors['maxlength'].requiredLength : 0 }}
                          characters, (Current
                          length: {{ initialsField?.errors ? initialsField?.errors['maxlength'].actualLength : 0 }}
                          )
                        </small>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="sp-flex-none" style="margin-bottom: 22px;">
                    <div class="sp-flex sp-flex-row sp-items-center">
                      <ngx-colors class="sp-inline-block" ngx-colors-trigger formControlName="colour"></ngx-colors>
                      <span class="sp-text-stone-600 sp-ml-2">
                       Initial Colour
                      </span>
                    </div>
                  </div>
                </div>
                <div class="sp-w-full sp-mb-8">
                  <mat-slide-toggle formControlName="show_colour_coding">
                    <span class="sp-text-stone-600 sp-ml-2">
                     Show Colour Coding on Kanban Cards
                    </span>
                  </mat-slide-toggle>
                </div>
                <div class="sp-w-full sp-mb-8">
                  <mat-slide-toggle formControlName="show_initials">
                    <span class="sp-text-stone-600 sp-ml-2">
                      Show Surveyor Initials In Kanban
                    </span>
                  </mat-slide-toggle>
                </div>
                <div class="sp-w-full sp-mb-8">
                  <mat-slide-toggle formControlName="show_brand_initials">
                    <span class="sp-text-stone-600 sp-ml-2">
                     Show Brand Initials In Kanban
                    </span>
                  </mat-slide-toggle>
                </div>
                <div class="sp-my-4">
                  <mat-divider></mat-divider>
                </div>
                <div class="sp-text-2xl sp-text-sp-blue sp-mb-4">Password Options</div>
                <sp-button type="button" class="sp-mr-2" icon="fa-duotone fa-repeat" (sp-click)="resetPassword()" colour="red">
                  Reset Password
                </sp-button>
              </div>
            </div>
          </div>
        </div>
        <div class="sp-px-4">
          <div class="sp-my-2">
            <mat-divider></mat-divider>
            <div class="sp-text-xl sp-text-sp-blue sp-my-4">Marketing Email</div>
            <div class="sp-w-full sp-mb-8">
              <mat-slide-toggle formControlName="marketing">
                <span class="sp-text-stone-600 sp-ml-2">
                 I would like to hear about the latest updates and news from SurvPal
                </span>
              </mat-slide-toggle>
            </div>
            <div class="sp-text-xl sp-text-sp-blue sp-my-4">Email Reminders</div>
            <div class="sp-w-full sp-mb-8">
              <mat-slide-toggle formControlName="reminders">
                <span class="sp-text-stone-600 sp-ml-2">
                 Enable Email Reminders For My Jobs
                </span>
              </mat-slide-toggle>
            </div>
            <div class="sp-text-xl sp-text-sp-blue sp-my-4">Brand Email Reminders</div>
            <div class="sp-w-full sp-my-4">
              <mat-selection-list (selectionChange)="updateLinkedBranding($event)">
                @for (brand of model.LinkedBrandList; track brand.ID) {
                  <mat-list-option [value]="brand.ID" [selected]="brand.IsChecked">{{ brand.Name }}</mat-list-option>
                }
              </mat-selection-list>
            </div>
            <sp-button [disabled]="formGroup.invalid" type="button" class="sp-mr-2" icon="fa-solid fa-check" (sp-click)="save()" colour="green">
              Save Changes
            </sp-button>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="sp-p-4">
          <div matTooltip="Click here to copy the link to your clipboard"
               (click)="copy(window.location.origin + '/IcalFeeds/' + model.UserID)"
               class="sp-p-4 sp-rounded sp-bg-stone-200 sp-cursor-pointer sp-flex sp-flex-row sp-items-center">
            <i class="sp-text-2xl fa-duotone fa-paste sp-mr-4"></i>
            <p class="!sp-my-0">
              Subscribe to ICal Feeds using this link: {{ window.location.origin }}/IcalFeeds/{{ model.UserGUID }}
            </p>
          </div>
        </div>

      </form>
    }
  </survpal-window>
</app-wrapper-container>

