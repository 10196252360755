import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {User} from "../models/user.model";
import {JobSearch} from "../models/job-search.model";

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient) {

  }

  public findJobs(search: string) {
    return this.http.get<JobSearch[]>(`/Job/SearchByJob?keyword=${encodeURIComponent(search)}`);
  }
}
