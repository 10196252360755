import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  constructor() {

  }

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  start() {
    this.loading.next(true);
  }

  stop() {
    this.loading.next(false);
  }
}
