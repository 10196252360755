import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTable, MatTableModule} from "@angular/material/table";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDividerModule} from "@angular/material/divider";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";
import {PackageComponent} from "../../components/shared/package/package.component";
import {spAnimations} from "../../helper/animation.helper";
import {CompanyService} from "../../services/company.service";
import {
  BillingPeriod,
  PaymentManagementViewModel,
  StripeCheckoutProduct,
  StripeCheckoutProductModel
} from "../../models/account-information.model";
import {LoadingService} from "../../services/loading.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute} from "@angular/router";
import {ConfirmComponent} from "../../dialogs/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {CreditUsageReportObject} from "../../models/credit-usage-report.model";
import {forkJoin} from "rxjs";
import {SubscriptionSettings} from "../../models/subscription-settings.model";

@Component({
  selector: 'survpal-account-information',
  standalone: true,
  imports: [
    DatePipe,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    MatDividerModule,
    SpButtonComponent,
    PackageComponent
  ],
  templateUrl: './account-information.component.html',
  styleUrl: './account-information.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountInformationComponent implements OnInit {

  public info?: PaymentManagementViewModel;
  public settings?: SubscriptionSettings;
  public Math = Math;
  public usage: CreditUsageReportObject[] = [];
  public usageColumns = ['when', 'what', 'ao', 'job_ref', 'by', 'credits']

  public page = 0;
  public perPage = 20;
  public totalItemCount = 0;
  public availableCredits: number = 0;

  @ViewChild('table')
  public table!: MatTable<any>

  constructor(private companyService: CompanyService, private dialog: MatDialog, private loadingService: LoadingService, private snackBar: MatSnackBar, private cdr: ChangeDetectorRef, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(x => {
      if (x["action"]) {
        switch (x['action']) {
          case 'payment_cancelled':
            this.snackBar.open("Payment was cancelled", '', {
              duration: 5000,
              panelClass: 'snackbar'
            });
            break;
          case 'payment_error':
            this.snackBar.open("There was an error processing your payment, please try again.", '', {
              duration: 5000,
              panelClass: 'snackbar'
            });
            break;
          case 'payment_successful':
            this.snackBar.open("Payment was successful, your credits will be added shortly!", '', {
              duration: 5000,
              panelClass: 'snackbar'
            });
            break;
          case 'no_plan':
            this.snackBar.open("You must sign up to one of our plans to start using SurvPal!", '', {
              duration: 10000,
              panelClass: 'snackbar'
            });
            break;

          case 'no_credits':
            this.snackBar.open("You don't have an active plan and you have no credits remaining, please sign up to a new plan!", '', {
              duration: 10000,
              panelClass: 'snackbar'
            });
            break;

          case 'no_payment':
            this.snackBar.open("You have one or more outstanding unpaid invoices, please pay these outstanding invoices to continue.", '', {
              duration: 10000,
              panelClass: 'snackbar'
            });
            break;
        }
      }
    })

    this.loadingService.start();

    forkJoin([
      this.companyService.getAccountInformation(),
      this.companyService.getCreditCount(),
      this.companyService.getAccountCreditUsage(this.perPage, this.perPage * this.page, this.info?.CurrentSubscription?.StartDate ?? "", this.info?.CurrentSubscription?.EndDate ?? ""),
      this.companyService.getTotalAccountCreditUsage(this.info?.CurrentSubscription?.StartDate ?? "", this.info?.CurrentSubscription?.EndDate ?? ""),
      this.companyService.getProductManagementSettings()
    ]).subscribe({
      next: ([info, credits, usage, totalCount, subscriptionSettings]) => {
        this.info = info;
        this.availableCredits = credits;
        this.settings = subscriptionSettings;
        this.usage = usage;
        this.totalItemCount = totalCount;
        this.loadingService.stop();
        this.cdr.detectChanges();
      },
      error: e => {
        this.loadingService.stop();
        this.snackBar.open("There was a problem fetching account information, please try again.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
        this.cdr.detectChanges();
      }
    })
  }

  public creditSubText = () => {
    switch (this.info?.CurrentSubscription?.BillingPeriod) {
      case BillingPeriod.OneOff:
        return '';
      case BillingPeriod.Days:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'days' : 'day'}`
      case BillingPeriod.Weeks:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'weeks' : 'week'}`
      case BillingPeriod.Months:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'months' : 'month'}`
      case BillingPeriod.Years:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'years' : 'year'}`
      default:
        return ``;
    }
  }

  public chargeSubText = () => {
    switch (this.info?.CurrentSubscription?.BillingPeriod) {
      case BillingPeriod.OneOff:
        return 'one off payment';
      case BillingPeriod.Days:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'days' : 'day'}`
      case BillingPeriod.Weeks:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'weeks' : 'week'}`
      case BillingPeriod.Months:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'months' : 'month'}`
      case BillingPeriod.Years:
        return `${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'every' : 'per'}${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? ' ' + this.info?.CurrentSubscription?.BillingPeriodCount : ''} ${this.info?.CurrentSubscription?.BillingPeriodCount > 1 ? 'years' : 'year'}`
      default:
        return ``;
    }
  }

  buyCredits(item: StripeCheckoutProductModel) {
    this.loadingService.start();
    this.companyService.purchaseCredits(item.ProductId, item.PriceId, 1).subscribe({
      next: i => {
        window.location.href = i;
      },
      error: e => {
        this.snackBar.open("There was a problem with this purchase, please try again.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
        this.loadingService.stop();
      }
    })
  }

  subscribeToPlan(item: StripeCheckoutProductModel) {
    if (this.inTrial() && item.Credits > 0) {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '400px',
        disableClose: true,
        data: {
          title: `Are you sure?`,
          message: `Are you sure you want to start a new plan whilst you are still in your free trial period? It is recommended to wait or move to a PAYG plan to maximise your free trial benefits.`
        }
      });
      dialogRef.afterClosed().subscribe(x => {
        if (x) {
          this.#subscribeToPlan(item);
        }
      });
    } else {
      this.#subscribeToPlan(item);
    }
  }

  #subscribeToPlan(item: StripeCheckoutProductModel) {
    this.loadingService.start();
    this.companyService.subscribeToPlan(item.ProductId, item.PriceId).subscribe({
      next: i => {
        window.location.href = i;
      },
      error: e => {
        this.snackBar.open("There was a problem with this purchase, please try again.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
        this.loadingService.stop();
      }
    })
  }

  toPage(page: number) {
    this.page = page;
    this.loadingService.start();
    this.companyService.getAccountCreditUsage(this.perPage, this.perPage * this.page, this.info?.CurrentSubscription?.StartDate ?? "", this.info?.CurrentSubscription?.EndDate ?? "").subscribe(
      {
        next: resp => {
          this.usage = resp;
          this.loadingService.stop();
          this.table.renderRows();
          this.cdr.detectChanges();
        },
        error: e => {
          this.loadingService.stop();
          this.snackBar.open("There was a problem fetching credit usage logs, please try again.", '', {
            duration: 5000,
            panelClass: 'snackbar'
          });
        }
      }
    )
  }

  isPAYG() {
    return this.info?.CurrentSubscription.Credits == 0;
  }

  inTrial() {
    return this.info?.CompanySubscription.FreeTrialEndDate && new Date(this.info?.CompanySubscription.FreeTrialEndDate) > new Date()
  }

  isCancelled() {
    return this.info?.CurrentSubscription?.CancellationDate && this?.info.CurrentSubscription?.CancelledAt;
  }

  hasCreditPacks() {
    return this.info?.CheckoutInfo.Products.some(x => x.Type == StripeCheckoutProduct.Credits);
  }

  hasSubscription(){
    return this.info?.CurrentSubscription != null;
  }

  protected readonly StripeCheckoutProduct = StripeCheckoutProduct;
}
