import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    const passwordValid = hasUpperCaseCharacter(value) && hasLowerCaseCharacter(value) && hasNumberCharacter(value) && hasSpecialCharacter(value);
    return !passwordValid ? {passwordStrength: true} : null;
  }
}

export function hasLowerCaseCharacter(value: string) {
  return /[a-z]/.test(value);
}

export function hasUpperCaseCharacter(value: string) {
  return /[A-Z]/.test(value);
}

export function hasNumberCharacter(value: string) {
  return /[0-9]/.test(value);
}

export function hasSpecialCharacter(value: string) {
  return /(?=.*[ -\/:-@\[-\`{-~]+)/.test(value);
}

export function getUpperCaseCheckClass(value: string) {
  return hasUpperCaseCharacter(value) ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
}

export function getLowerCaseCheckClass(value: string) {
  return hasLowerCaseCharacter(value) ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
}

export function getNumberCaseCheckClass(value: string) {
  return hasNumberCharacter(value) ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
}

export function getSpecialCaseCheckClass(value: string) {
  return hasSpecialCharacter(value) ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
}
