import {Component} from '@angular/core';
import {spAnimations} from "../../helper/animation.helper";
import {FormControl, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmComponent} from "../../dialogs/confirm/confirm.component";
import {LoadingService} from "../../services/loading.service";
import {UserService} from "../../services/user.service";
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'survpal-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: spAnimations
})
export class ResetPasswordComponent {

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private loadingService: LoadingService, private userService: UserService, private recaptchaV3Service: ReCaptchaV3Service,) {
  }

  public emailControl = new FormControl('', [Validators.required, Validators.email]);
  public usernameControl = new FormControl('', [Validators.required]);

  resetPassword() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: {
        title: `Information`,
        message: `Do you wish to reset your password?`
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.loadingService.start();
        this.recaptchaV3Service.execute('resetPasswordAction').subscribe({
          next: token => {
            this.userService.resetPassword(this.usernameControl.value as string, token).subscribe({
              next: x => {
                this.loadingService.stop();
                this.snackBar.open(x, 'Ok', {
                  duration: 5000,
                  panelClass: 'reset-snackbar'
                });
                this.usernameControl.setValue("");
              },
              error: e => this.#genericError()
            });
          },
          error: e => this.#genericError()
        });
      }
    });
  }

  getUsername() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: {
        title: `Information`,
        message: `Do you wish to receive an email with your registered username?`
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.loadingService.start();
        this.recaptchaV3Service.execute('getUsernameAction').subscribe({
          next: token => {
            this.userService.getUsername(this.emailControl.value as string, token).subscribe({
              next: x => {
                this.loadingService.stop();
                this.snackBar.open(x, 'Ok', {
                  duration: 5000,
                  panelClass: 'reset-snackbar'
                });
                this.emailControl.setValue("");
              },
              error: e => this.#genericError()
            })
          },
          error: e => this.#genericError()
        });
      }
    });
  }

  #genericError() {
    this.loadingService.stop();
    this.snackBar.open("An error occurred, please try again.", 'Ok', {
      duration: 5000,
      panelClass: 'register-snackbar'
    });
  }
}
