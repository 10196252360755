<div [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}">

  <div class="sp-text-2xl sp-text-sp-blue sp-w-auto">Download Summary</div>
  <table #table mat-table [dataSource]="items" class="sp-mt-4 mat-elevation-z0">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Letter Name </th>
      <td mat-cell *matCellDef="let element"> {{element.LetterName}} </td>
    </ng-container>

    <ng-container matColumnDef="downloads">
      <th mat-header-cell *matHeaderCellDef> Downloads </th>
      <td mat-cell *matCellDef="let element"> {{element.Downloads}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
