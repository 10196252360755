import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LoginModel} from "../models/login.model";
import {LoginResponseModel} from "../models/login-response.model";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {

  }

  public login(username: string, password: string, remember: boolean, token: string, returnUrl: string) {
    let model: LoginModel = {
      UserName: username,
      Password: password,
      RememberMe: remember,
      Token: token,
      ReturnUrl: returnUrl
    };
    return this.http.post<LoginResponseModel>("/account/login", model);
  }

  public logout(){
    return this.http.get("/account/logout");
  }
}
