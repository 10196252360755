export enum UserRoles {
  None = 0,
  Surveyor = 1,
  NamedSurveyor = 2
}

export function getRoleName(role: UserRoles) {
  if (role == UserRoles.None) {
    return "Coordinator";
  }

  if (role == UserRoles.Surveyor) {
    return "Surveyor";
  }

  if (role == UserRoles.NamedSurveyor) {
    return "Named Surveyor"
  }

  return "-";
}
