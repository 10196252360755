import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {User} from "../models/user.model";
import {BehaviorSubject, map} from "rxjs";
import {Branding} from "../models/branding.model";

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  constructor(private http: HttpClient) {

  }

  public getBrands() {
    return this.http.get<Branding[]>("/brands");
  }
}
