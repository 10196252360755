import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CompanyService} from "../../services/company.service";
import {spAnimations} from "../../helper/animation.helper";
import {LoadingService} from "../../services/loading.service";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatMenuModule} from "@angular/material/menu";
import {MatTable, MatTableModule} from "@angular/material/table";
import {DatePipe, NgClass} from "@angular/common";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";
import {CreditLog} from "../../models/credit-log.model";
import {forkJoin} from "rxjs";
import { DateTime } from 'luxon';
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'survpal-credit-logs',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatTableModule,
    DatePipe,
    MatPaginatorModule,
    SpButtonComponent,
    NgClass,
    MatTooltipModule
  ],
  templateUrl: './credit-logs.component.html',
  styleUrl: './credit-logs.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditLogsComponent implements OnInit {

  public logs: CreditLog[] = [];
  public usageColumns = ['product', 'credits', 'used', 'remaining', 'expiry'];
  public totalItemCount = 0;
  public perPage = 20;
  public page = 0;

  @ViewChild('table')
  public table!: MatTable<any>

  constructor(private companyService: CompanyService, private loadingService: LoadingService, private cdr: ChangeDetectorRef, private snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.loadingService.start();
    this.page = 0;

    forkJoin([
      this.companyService.getCreditLogs(this.perPage, 0, "", ""),
      this.companyService.getTotalCreditLogs("", "")
    ]).subscribe({
      next: ([logs, totalCount]) => {
        this.logs = logs;
        this.totalItemCount = totalCount;
        this.loadingService.stop();
        this.cdr.detectChanges();
      },
      error: err => {
        this.loadingService.stop();
        this.snackBar.open("There was a problem fetching credit purchase logs, please try again.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
      }
    })
  }

  toPage(page: number) {
    this.page = page;
    this.loadingService.start();
    this.companyService.getCreditLogs(this.perPage, this.perPage * this.page, "", "").subscribe(
      {
        next: resp => {
          this.logs = resp;
          this.loadingService.stop();
          this.table.renderRows();
          this.cdr.detectChanges();
        },
        error: e => {
          this.loadingService.stop();
          this.snackBar.open("There was a problem fetching credit usage logs, please try again.", '', {
            duration: 5000,
            panelClass: 'snackbar'
          });
        }
      }
    )
  }

  expired(log: CreditLog) {
    return DateTime.fromISO(log.expiry as string) <= DateTime.utc()
  }
}
