import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  AppWrapperContainerComponent
} from "../../components/shared/app-wrapper-container/app-wrapper-container.component";
import {MatTabsModule} from "@angular/material/tabs";
import {RouterOutlet} from "@angular/router";
import {SpWindowComponent} from "../../components/shared/sp-window/sp-window.component";
import {spAnimations} from "../../helper/animation.helper";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MyDetails} from "../../models/my-details.model";
import {LoadingService} from "../../services/loading.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subscription} from "rxjs";
import {copyToClipboard} from "../../helper/copy.helper";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatListModule, MatSelectionListChange} from "@angular/material/list";
import {UserBranding} from "../../models/user-branding.model";
import {NgxColorsModule} from "ngx-colors";
import {NgIf} from "@angular/common";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordComponent} from "../../dialogs/change-password/change-password.component";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'my-details',
  standalone: true,
  imports: [
    AppWrapperContainerComponent,
    MatTabsModule,
    RouterOutlet,
    SpWindowComponent,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatListModule,
    NgxColorsModule,
    NgIf,
    SpButtonComponent
  ],
  templateUrl: './my-details.component.html',
  styleUrl: './my-details.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyDetailsComponent implements OnInit, OnDestroy {
  loading = true;
  model: MyDetails = new MyDetails();
  subscriptions: Subscription[] = [];
  formGroup!: FormGroup

  constructor(private cdr: ChangeDetectorRef, private dialog: MatDialog, private loadingService: LoadingService, private formBuilder: FormBuilder, private userService: UserService, private snackBar: MatSnackBar) {
    this.loadingService.start();
  }

  ngOnInit() {
    this.userService.getCurrentUserDetails().subscribe(x => {
      this.model = x;
      this.buildForm();
      this.loading = false;
      this.loadingService.stop();
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildForm() {

    let fields = {
      first_name: [this.model.FirstName || '', [Validators.required, Validators.maxLength(50)]],
      last_name: [this.model.LastName || '', [Validators.required, Validators.maxLength(50)]],
      telephone: [this.model.Fax || '', [Validators.maxLength(50)]],
      mobile: [this.model.MobilePhone || '', [Validators.maxLength(50)]],
      email: [this.model.Email || '', [Validators.required, Validators.email]],
      qualifications: [this.model.Qualification || ''],
      initials: [this.model.Initials || '', [Validators.required, Validators.minLength(2), Validators.maxLength(3)]],
      marketing: [this.model.EmailUpdates || false],
      reminders: [this.model.EnableEmailReminders || false],
      colour: [this.model.InitialColour || '#8fb340'],
      show_colour_coding: [this.model.KanBanColorCodeVisibility || false],
      show_initials: [this.model.ShowInitials || false],
      show_brand_initials: [this.model.ShowBrandInitials || false]
    };

    this.model.LinkedBrandList.forEach(x => {
      (fields as any)[x.ID] = [x.IsChecked || false];
    })

    this.formGroup = this.formBuilder.group(fields);
  }

  copy(value: string) {
    copyToClipboard(value, this.snackBar);
  }

  get firstNameField() {
    return this.formGroup.get('first_name');
  }

  get lastNameField() {
    return this.formGroup.get('last_name');
  }

  get emailField() {
    return this.formGroup.get('email');
  }

  get telephoneNumberField() {
    return this.formGroup.get('telephone');
  }

  get mobileNumberField() {
    return this.formGroup.get('mobile');
  }

  get initialsField() {
    return this.formGroup.get('initials');
  }

  resetPassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '600px',
      data: {
        title: `Change your password`
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.loadingService.start();
        this.userService.updatePassword(x.old_password, x.password).subscribe({
          next: val => {
            this.loadingService.stop();
            this.snackBar.open("Successfully updated your password!", '', {
              duration: 5000,
              panelClass: 'snackbar'
            });
          },
          error: e => {
            this.loadingService.stop();
            this.snackBar.open("There was a problem updating your password, please try again!.", '', {
              duration: 5000,
              panelClass: 'snackbar'
            });
          }
        })
      }
    });
  }

  save() {
    this.model.FirstName = this.formGroup.get('first_name')?.value;
    this.model.LastName = this.formGroup.get('last_name')?.value;
    this.model.Fax = this.formGroup.get('telephone')?.value;
    this.model.MobilePhone = this.formGroup.get('mobile')?.value;
    this.model.Email = this.formGroup.get('email')?.value;
    this.model.Qualification = this.formGroup.get('qualifications')?.value;
    this.model.Initials = this.formGroup.get('initials')?.value;
    this.model.EmailUpdates = this.formGroup.get('marketing')?.value;
    this.model.EnableEmailReminders = this.formGroup.get('reminders')?.value;
    this.model.InitialColour = this.formGroup.get('colour')?.value;
    this.model.KanBanColorCodeVisibility = this.formGroup.get('show_colour_coding')?.value;
    this.model.ShowInitials = this.formGroup.get('show_initials')?.value;
    this.model.ShowBrandInitials = this.formGroup.get('show_brand_initials')?.value;

    this.model.LinkedBrandList.forEach(x => {
      x.IsChecked = this.formGroup.get(x.ID)?.value;
    })

    this.loadingService.start();
    this.userService.setCurrentUserDetails(this.model).subscribe({
      next: () => {
        this.loadingService.stop();
        this.snackBar.open("Successfully saved your details!.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
      },
      error: (e: any) => {
        this.loadingService.stop();
        this.snackBar.open("There was an issue saving your details, please try again.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
      }
    })
  }

  updateLinkedBranding(event: MatSelectionListChange) {
    event.options.forEach(x => {
      this.formGroup.get(x.value)?.setValue(x.selected);
    })
  }

  protected readonly window = window;
}
