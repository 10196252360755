export class PaymentManagementViewModel {
  public CompanySubscription!: CompanySubscription;
  public FreeTrialPeriod!: boolean;
  public FreeTrialEndDate?: Date | null;
  public CheckoutInfo!: StripeCheckoutProductList;
  public CurrentSubscription!: StripeSubscriptionViewModel

  constructor() {
    this.FreeTrialPeriod = false;
    this.FreeTrialEndDate = null;
  }
}

export class StripeSubscriptionViewModel {
  public Id!: string
  public ProductName!: string;
  public StartDate!: string;
  public EndDate!: string;
  public CancellationDate?: Date;
  public CancelledAt?: Date;

  public Price!: number;
  public BillingPeriod!: BillingPeriod;
  public BillingPeriodCount!: number;
  public Credits!: number;
}

export class CompanySubscription {
  public Id!: string;
  public CompanyInfoId!: string;
  public CustomerId!: string;
  public SubscriptionId!: string;
  public FreeTrialEndDate!: Date;
  public IsNew!: boolean;
  public BillableCredits!: number;
}

export enum StripeCheckoutProduct {
  Subscription = 0,
  Credits = 1
}

export enum StripeCheckoutProductType {
  Subscription = 0,
  OneOff = 1,
}

export enum BillingPeriod {
  OneOff = 0,
  Days = 1,
  Weeks = 2,
  Months = 3,
  Years = 4
}

export class StripeCheckoutProductList {
  public Products!: StripeCheckoutProductModel[];

  constructor() {
    this.Products = [];
  }
}

export class StripeCheckoutProductViewModel {
  public Type!: StripeCheckoutProductType;
  public Checkout: boolean;
  public Products!: StripeCheckoutProductModel[];

  constructor() {
    this.Checkout = false;
    this.Products = [];
  }
}

export class StripeCheckoutProductModel {
  public ProductId!: string;
  public PriceId!: string;
  public Type!: StripeCheckoutProduct;

  public ProductName!: string;
  public Price!: number;
  public BillingPeriod!: BillingPeriod;
  public BillingPeriodCount!: number;

  public Credits!: number;
}

export enum StripePurchaseType
{
  Subscription = 0,
  Credits = 1
}
