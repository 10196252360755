import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from "../models/user.model";
import {BehaviorSubject, map} from "rxjs";
import {AccountDetails} from "../models/account-details.model";
import {AccountDetailsResponse} from "../models/account-details-response.model";
import {LegacyDownloadSummary} from "../models/legacy-download-summary.model";
import {DownloadLogResponse} from "../models/download-log-response.model";
import {PaymentManagementViewModel, StripePurchaseType} from "../models/account-information.model";
import {CreditUsageReportObject} from "../models/credit-usage-report.model";
import {CreditLog} from "../models/credit-log.model";
import {SubscriptionSettings} from "../models/subscription-settings.model";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public creditCount = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {

  }

  getCreditCount() {
    return this.http.get<number>("account/get-credit-count");
  }

  loadDetails() {
    return this.http.get<AccountDetailsResponse>('registration/account/details');
  }

  saveDetails(details: AccountDetails) {
    return this.http.post('registration/edit-company-account-details', details)
  }

  getDownloadSummary() {
    return this.http.get<LegacyDownloadSummary[]>('registration/download-summary');
  }

  getDownloadLogs(search: string, limit: number, page: number) {
    return search?.length > 0 ? this.http.get<DownloadLogResponse>(`registration/download-logs/${encodeURIComponent(search)}/${limit}/${page}`) : this.http.get<DownloadLogResponse>(`registration/download-logs/${limit}/${page}`);
  }

  getAccountInformation() {
    return this.http.get<PaymentManagementViewModel>('/client-admin/get-payment-management-model');
  }

  getProductManagementSettings() {
    return this.http.get<SubscriptionSettings>('/account/get-product-management-settings');
  }

  getTotalAccountCreditUsage(from: string, to: string) {

    if (from == "") {
      return this.http.get<number>(`/account/get-total-credit-usage`)
    }

    return this.http.get<number>(`/account/get-total-credit-usage/${encodeURIComponent(from)}/${encodeURIComponent(to)}`)
  }

  getAccountCreditUsage(limit: number, offset: number, from: string, to: string) {

    if (from == "") {
      return this.http.get<CreditUsageReportObject[]>(`/account/get-credit-usage/${limit}/${offset}`)
    }

    return this.http.get<CreditUsageReportObject[]>(`/account/get-credit-usage/${limit}/${offset}/${encodeURIComponent(from)}/${encodeURIComponent(to)}`)
  }

  getCreditLogs(limit: number, offset: number, from: string, to: string) {
    if (from == "") {
      return this.http.get<CreditLog[]>(`/account/get-credit-logs/${limit}/${offset}`)
    }

    return this.http.get<CreditLog[]>(`/account/get-credit-logs/${limit}/${offset}/${encodeURIComponent(from)}/${encodeURIComponent(to)}`)
  }

  getTotalCreditLogs(from: string, to: string) {

    if (from == "") {
      return this.http.get<number>(`/account/get-total-credit-logs`)
    }

    return this.http.get<number>(`/account/get-total-credit-logs/${encodeURIComponent(from)}/${encodeURIComponent(to)}`)
  }

  purchaseCredits(productId: string, priceId: string, quantity: number) {
    return this.http.post<string>('/payment-management/purchase-credits', {
      tProductId: productId,
      tPriceId: priceId,
      tType: StripePurchaseType.Credits,
      tQuantity: quantity
    })
  }

  subscribeToPlan(productId: string, priceId: string) {
    return this.http.post<string>('/payment-management/purchase-subscription', {
      sProductId: productId,
      sPriceId: priceId,
      sType: StripePurchaseType.Subscription
    })
  }
}
