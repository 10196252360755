<div [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}">


    <div class="sp-flex sp-flex-row sp-w-full sp-mb-3">
        <div class="sp-text-2xl sp-text-sp-blue sp-w-auto">Credit Usage</div>
        <a class="sp-ml-auto" target="_blank" href="/account/export-credit-usage">
            <sp-button class="sp-block sp-mt-auto" icon="fa-duotone fa-file-csv">
                Download Usage Logs
            </sp-button>
        </a>
    </div>
    <table #table mat-table [dataSource]="usage" class="sp-mt-4 mat-elevation-z0">
        <ng-container matColumnDef="when">
            <th mat-header-cell *matHeaderCellDef> When</th>
            <td mat-cell *matCellDef="let element">
                <div>
                    {{ element.created_date + "Z" | date : 'dd/MM/yyyy' }}
                </div>
                <div class="!sp-text-xs sp-text-stone-500">{{ element.created_date + "Z" | date : 'HH:mm:ss' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="what">
            <th mat-header-cell *matHeaderCellDef> What</th>
            <td mat-cell *matCellDef="let element"> {{ element.item }}</td>
        </ng-container>

        <ng-container matColumnDef="ao">
            <th mat-header-cell *matHeaderCellDef> Adjoining Owner Information</th>
            <td mat-cell *matCellDef="let element">
                <div class="sp-flex sp-flex-col">
                    <div>{{ element.name }}</div>
                    <div class="!sp-text-xs sp-text-stone-500">{{ element.address }}</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="job_ref">
            <th mat-header-cell *matHeaderCellDef> Job Reference</th>
            <td mat-cell *matCellDef="let element">
                <div class="sp-flex sp-flex-col">
                    <div>{{ element.jobref }}</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="by">
            <th mat-header-cell *matHeaderCellDef> User</th>
            <td mat-cell *matCellDef="let element">
                <div class="sp-flex sp-flex-col">
                    <div>{{ element.user }}</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="credits">
            <th mat-header-cell *matHeaderCellDef>Credit Cost</th>
            <td mat-cell *matCellDef="let element"> {{ element.consumption }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="usageColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: usageColumns;"></tr>
    </table>
    @if (totalItemCount > 0) {
        <mat-paginator class="my-4" [length]="totalItemCount"
                       [pageSize]="perPage" (page)="toPage($event.pageIndex)">
        </mat-paginator>
    }
</div>
