import {UserRoles} from "../enums/user-roles.enum";
import {UserBrand} from "./user-brand.model";

export class Password {
  public user_id!: number;
  public old_password!: string;
  public confirm_password!: string;
  public new_password!: string;

  constructor() {

  }
}
