export class RegistrationModel {
  public CompanyName? : string;
  public StreetName?: string;
  public HouseName?: string;
  public HouseNo?: string;
  public AddressLine2? : string;
  public City? : string;
  public County? : string;
  public PostCode? : string;
  public Phone? : string;
  public UserName? : string;
  public Email? : string;
  public FirstName? : string;
  public LastName? : string;
  public Fax? : string;
  public MobilePhone? : string;
  public Qualification? : string;
  public Password? : string;
  public ConfirmPassword? : string;
  public Terms?: boolean;
  public EmailUpdates?: boolean;
  public Token?: string;
}


//Company Info



