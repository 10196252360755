import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {spAnimations} from "../../helper/animation.helper";
import {RegistrationModel} from "../../models/registration.model";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {RegisterService} from "../../services/register.service";
import {LoadingService} from "../../services/loading.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmComponent} from "../../dialogs/confirm/confirm.component";
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'survpal-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit {

  public model: RegistrationModel = new RegistrationModel();
  public formGroup!: FormGroup

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, private registrationService: RegisterService, private loadingService: LoadingService, private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {

    let fields = {
      company_name: [this.model.CompanyName || '', Validators.required],
      house_no: [this.model.HouseNo || '', [Validators.maxLength(50)]],
      house_name: [this.model.HouseName || ''],
      street_name: [this.model.StreetName || '', Validators.required],
      address_line_2: [this.model.AddressLine2 || ''],
      city: [this.model.City || '', [Validators.maxLength(50)]],
      county: [this.model.County || '', [Validators.maxLength(50)]],
      post_code: [this.model.PostCode || '', [Validators.required, Validators.maxLength(10), Validators.pattern(/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/)]],
      contact_number: [this.model.Phone || '', [Validators.maxLength(50)]],
      email: [this.model.Email || '', [Validators.required, Validators.email]],
      first_name: [this.model.FirstName || '', [Validators.required, Validators.maxLength(50)]],
      last_name: [this.model.LastName || '', [Validators.required, Validators.maxLength(50)]],
      qualifications: [this.model.Qualification || ''],
      password: [this.model.Password || '', [this.createPasswordStrengthValidator(), Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      confirm_password: [this.model.ConfirmPassword || '', [this.passwordMatch()]],
      terms: [false, [Validators.required]],
      permission: [false],
    };

    this.formGroup = this.formBuilder.group(fields);
  }

  createPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const value = control.value;

      if (!value) {
        return null;
      }

      const passwordValid = this.hasUpperCaseCharacter() && this.hasLowerCaseCharacter() && this.hasNumberCharacter() && this.hasSpecialCharacter();
      return !passwordValid ? {passwordStrength: true} : null;
    }
  }

  get passwordField() {
    return this.formGroup.get('password');
  }

  get password2Field() {
    return this.formGroup.get('confirm_password');
  }

  get firstNameField() {
    return this.formGroup.get('first_name');
  }

  get companyNameField() {
    return this.formGroup.get('company_name');
  }

  get streetNameField() {
    return this.formGroup.get('street_name');
  }

  get houseNoField() {
    return this.formGroup.get('house_no');
  }

  get houseNameField() {
    return this.formGroup.get('house_name');
  }

  get cityField() {
    return this.formGroup.get('city');
  }

  get countyField() {
    return this.formGroup.get('county');
  }

  get postcodeField() {
    return this.formGroup.get('post_code');
  }

  get lastNameField() {
    return this.formGroup.get('last_name');
  }

  get permissionField(): UntypedFormControl {
    return this.formGroup.get('permission') as UntypedFormControl;
  }

  get termsField(): UntypedFormControl {
    return this.formGroup.get('terms') as UntypedFormControl;
  }

  get emailField() {
    return this.formGroup.get('email');
  }

  get contactNumberField() {
    return this.formGroup.get('contact_number');
  }

  hasLowerCaseCharacter() {
    return /[a-z]/.test(this.formGroup.get("password")?.value);
  }

  hasUpperCaseCharacter() {
    return /[A-Z]/.test(this.formGroup.get("password")?.value);
  }

  hasNumberCharacter() {
    return /[0-9]/.test(this.formGroup.get("password")?.value);
  }

  hasSpecialCharacter() {
    return /(?=.*[ -\/:-@\[-\`{-~]+)/.test(this.formGroup.get("password")?.value);
  }

  getUpperCaseCheckClass() {
    return this.hasUpperCaseCharacter() ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
  }

  getLowerCaseCheckClass() {
    return this.hasLowerCaseCharacter() ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
  }

  getNumberCaseCheckClass() {
    return this.hasNumberCharacter() ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
  }

  getSpecialCaseCheckClass() {
    return this.hasSpecialCharacter() ? 'fa-solid fa-check sp-text-green-700 sp-ml-2 icon' : 'fa-solid fa-times sp-text-red-700 sp-ml-2 icon';
  }

  passwordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!this.formGroup) {
        return null;
      }
      return value != this.formGroup.get('password')?.value ? {passwordMatch: true} : null;
    }
  }

  save() {

    this.model.CompanyName = this.formGroup.get('company_name')!.value;
    this.model.StreetName = this.formGroup.get('street_name')!.value;
    this.model.HouseName = this.formGroup.get('house_name')!.value;
    this.model.HouseNo = this.formGroup.get('house_no')!.value;
    this.model.AddressLine2 = this.formGroup.get('address_line_2')!.value;
    this.model.City = this.formGroup.get('city')!.value;
    this.model.County = this.formGroup.get('county')!.value;
    this.model.PostCode = this.formGroup.get('post_code')!.value;
    this.model.Phone = this.formGroup.get('contact_number')!.value;
    this.model.Email = this.formGroup.get('email')!.value;
    this.model.FirstName = this.formGroup.get('first_name')!.value;
    this.model.LastName = this.formGroup.get('last_name')!.value;
    this.model.Qualification = this.formGroup.get('qualifications')!.value;
    this.model.Password = this.formGroup.get('password')!.value;
    this.model.EmailUpdates = this.formGroup.get('permission')!.value;
    this.model.Terms = this.formGroup.get('terms')!.value;

    this.loadingService.start();
    this.registrationService.isRegistered(this.model.CompanyName as string, this.model.PostCode as string).subscribe({
      next: x => {
        this.#save();
      },
      error: e => {
        this.loadingService.stop();
        const dialogRef = this.dialog.open(ConfirmComponent, {
          width: '400px',
          data: {
            title: `Information`,
            message: `This Company is already registered with SurvPal. Please contact your System Administrator to create your user. If you want to continue anyway, please click on 'Ok'`
          }
        });
        dialogRef.afterClosed().subscribe(x => {
          this.loadingService.start();
          if (x) {
            this.#save();
          }
        });
      }
    });
  }

  #save(){
    this.recaptchaV3Service.execute('registerAction').subscribe({
      next: x =>
      {
        this.model.Token = x;
        this.registrationService.register(this.model).subscribe({
          next: x => {
            this.loadingService.stop();
            if (x.result && (x.redirect?.length ?? 0) > 0) {
              window.location.href = x.redirect as string;
              return;
            }

            if ((x.message?.length ?? 0) > 0) {
              this.snackBar.open(x.message, '', {
                duration: 5000,
                panelClass: 'register-snackbar'
              });
            }
          },
          error: e => this.#genericError()
        })
      },
      error: e => this.#genericError()
    })
  }

  #genericError(){
    this.loadingService.stop();
    this.snackBar.open("An error occurred whilst trying to register you with SurvPal, please try again.", '', {
      duration: 5000,
      panelClass: 'register-snackbar'
    });
  }
}
