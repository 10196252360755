import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {User} from "../../models/user.model";
import {AddEditUserDialogDataModel} from "../../models/add-edit-user.model";
import {LoadingService} from "../../services/loading.service";
import {UserService} from "../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RoleHelper} from "../../helper/role.helper";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSelectModule} from "@angular/material/select";
import {UserRoles} from "../../enums/user-roles.enum";
import {MatListModule} from "@angular/material/list";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipInputEvent, MatChipSelectionChange, MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {BrandService} from "../../services/brand.service";
import {Branding} from "../../models/branding.model";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {map, Observable, startWith} from "rxjs";
import {AsyncPipe, NgIf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {UserBrand} from "../../models/user-brand.model";
import {LegacySaveUser} from "../../models/legacy-save-user.model";

@Component({
  selector: 'survpal-add-edit-user',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatListModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatIconModule,
    SpButtonComponent,
    MatTooltipModule,
    NgIf
  ],
  templateUrl: './add-edit-user.component.html',
  styleUrl: './add-edit-user.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditUserComponent implements OnInit {

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public currentUser = this.userService.currentUser.value;
  public model: User = new User();
  public formGroup!: FormGroup;
  public loaded = false;
  public namedSurveyorCount = 0;

  @ViewChild('brandInput')
  public brandInput!: ElementRef<HTMLInputElement>;
  public brandCtrl = new FormControl('');
  public filteredBrands!: Observable<string[]>;

  public allBrands: Branding[] = [];
  public availableBrands: Branding[] = [];

  protected readonly UserRoles = UserRoles;

  constructor(
    public dialogRef: MatDialogRef<AddEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddEditUserDialogDataModel,
    private formBuilder: FormBuilder, private loadingService: LoadingService, private userService: UserService,
    private cdr: ChangeDetectorRef, private snackBar: MatSnackBar, private brandService: BrandService) {
    this.loadingService.start();
  }

  ngOnInit() {
    this.userService.getNamedSurveyorCount().subscribe(x => {
      this.namedSurveyorCount = x;
      this.brandService.getBrands().subscribe(x => {
        this.allBrands = x;
        if (this.data.id != null) {
          this.userService.getUser(this.data.id).subscribe({
              next: x => {
                this.model = x;
                this.buildForm();
                this.loaded = true;
                this.loadingService.stop();
                this.cdr.detectChanges();
              },
              error: (e) => {
                this.snackBar.open("An error occurred whilst trying to load this user, please try again.", '', {
                  duration: 5000,
                  panelClass: 'snackbar'
                });
                this.dialogRef.close(false);
              }
            }
          )
        } else {
          this.loadingService.stop();
          this.buildForm();
          this.loaded = true;
        }
        this.cdr.detectChanges();
      })
    });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  buildForm() {
    let fields = {
      first_name: [this.model.first_name ?? '', [Validators.required, Validators.maxLength(50)]],
      last_name: [this.model.last_name ?? '', [Validators.required, Validators.maxLength(50)]],
      email: [this.model.email ?? '', [Validators.required, Validators.email]],
      qualifications: [this.model.qualifications ?? ''],
      admin: [this.model.roles.includes(RoleHelper.AdminRole)],
      permission: [this.model.permission, [Validators.required]],
    };

    if (this.model.brands.length == 0 && this.allBrands.length == 1 && this.model.id == null) {
      this.model.brands.push({id: this.allBrands[0].id, updates: true});
    }

    this.availableBrands = this.allBrands.filter(x => !this.model.brands.map(x => x.id).includes(x.id));

    this.filteredBrands = this.brandCtrl.valueChanges.pipe(
      startWith(null),
      map((search: string | null) => this._filter(search)),
    ) as any;

    this.formGroup = this.formBuilder.group(fields);

    this.formGroup.get('brands')?.valueChanges.subscribe(x => {
      this.model.brands = x.map((b: Branding) => b.id);
    })

    if (this.namedSurveyorCount <= 1 && this.model.permission == UserRoles.NamedSurveyor) {
      this.formGroup.get('permission')?.disable()
    }

    if (this.currentUser.id == this.model.id) {
      this.formGroup.get('admin')?.disable();
    }
  }

  get firstNameField() {
    return this.formGroup.get('first_name');
  }

  get lastNameField() {
    return this.formGroup.get('last_name');
  }

  get emailField() {
    return this.formGroup.get('email');
  }

  remove(id: string): void {
    let brand = this.allBrands[this.allBrands.map(x => x.id).indexOf(id)]
    this.availableBrands.push(brand);
    let index = this.model.brands.findIndex(x => x.id == brand.id);
    this.model.brands.splice(index, 1);
    this.brandCtrl.setValue(null);
    this.cdr.detectChanges();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.availableBrands.indexOf(event.option.value);
    let brand = this.availableBrands.splice(index, 1);
    this.brandInput.nativeElement.value = '';
    this.brandCtrl.setValue(null);
    this.model.brands.push({id: brand[0].id, updates: true})
  }

  getName(id: string) {
    return this.allBrands.find(x => x.id == id)?.name;
  }

  getBrand(id: string) {
    return this.allBrands.find(x => x.id == id);
  }

  save() {
    this.model.first_name = this.formGroup.get('first_name')?.value;
    this.model.last_name = this.formGroup.get('last_name')?.value;
    this.model.email = this.formGroup.get('email')?.value;
    this.model.qualifications = this.formGroup.get('qualifications')?.value;
    this.model.roles = this.formGroup.get('admin')?.value ? [RoleHelper.AdminRole] : [];
    this.model.permission = this.formGroup.get('permission')?.value;

    let saveModel: LegacySaveUser = {
      Administrator: this.formGroup.get('admin')?.value,
      AllBrands: this.allBrands.map(x => {
        return {ID: x.id, Name: x.name, IsChecked: false}
      }),
      Branding: false,
      Email: this.model.email,
      EmailUpdates: false,
      EnableEmailReminders: false,
      Fax: "",
      FirstName: this.model.first_name,
      Group: [],
      IfOnlyOneAdmin: false,
      IfOnlyOneNamedSurveyor: this.namedSurveyorCount <= 1,
      InitialColour: "",
      Initials: "",
      IsLinkedToAnyJob: false,
      IsSupport: false,
      KanBanColorCodeVisibility: false,
      KanBanColorShowFinalInspectionStage: false,
      LastName: this.formGroup.get('last_name')?.value,
      LinkedBrandList: this.model.brands.map(x => {
        return {ID: x.id, Name: "", IsChecked: x.updates}
      }),
      MobilePhone: "",
      Qualification: this.model.qualifications,
      Role: this.model.permission,
      ShowBrandInitials: false,
      ShowInitials: false,
      UserID: this.model.id as number
    };

    this.loadingService.start();
    this.userService.save(saveModel).subscribe({
      next: e => {
        this.model.id = e.id;
        this.model.username = e.username;
        this.loadingService.stop();
        if (this.model.id != null) {
          this.snackBar.open(`Successfully saved updates to: ${this.model.first_name} ${this.model.last_name}.`, '', {
            duration: 5000,
            panelClass: 'snackbar'
          });
        } else {
          this.snackBar.open(`Successfully saved the new user: ${this.model.first_name} ${this.model.last_name}.`, '', {
            duration: 5000,
            panelClass: 'snackbar'
          });
        }
        this.userService.updateUser.next(this.model);
        this.cancel();
      },
      error: e => {
        this.loadingService.stop();
        this.snackBar.open("An error occurred whilst trying to save this user, please try again.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
      }
    })
  }

  private _filter(value: string | null): Branding[] {
    if (value == null) value = "";
    if (typeof (value) == "object") {
      value = (value as Branding).name;
    }
    const filterValue = value.toLowerCase();
    let selected = this.model.brands;
    return this.availableBrands.filter(brand => brand.name.toLowerCase().includes(filterValue) && !selected.map(x => x.id).includes(brand.id));
  }

  updateEmailStatus(ev: MatChipSelectionChange) {
    this.model.brands.find(x => x.id == ev.source.value.id)!.updates = ev.selected;
    this.cdr.detectChanges();
  }

  protected readonly RoleHelper = RoleHelper;
}

