import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";

@Component({
  selector: 'survpal-support-request',
  templateUrl: './support-request.component.html',
  standalone: true,
  imports: [
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    SpButtonComponent

  ],
  styleUrls: ['./support-request.component.scss']
})
export class SupportRequestComponent {

  public control = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<SupportRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(this.control.value);
  }
}
