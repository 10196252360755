<div [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}" class="sp-h-full">
  <div class="sp-w-full sp-flex sp-flex-row sp-items-center">
    <div class="sp-text-2xl sp-mb-3 sp-text-sp-blue sp-w-auto">Users</div>
    <div class="sp-ml-auto sp-flex sp-flex-row sp-items-center">
      <sp-button class="sp-mr-2" icon="fa-solid fa-plus" (sp-click)="addUser()" colour="green">
        Create New User
      </sp-button>
      <sp-button [disabled]="hasSupport()" [tooltip]="hasSupport() ? 'You already have an active support account' : 'You are able to request support from Survpal'" class="sp-ml-2" icon="fa-duotone fa-headset" (sp-click)="requestSupport()" colour="green">
        Request Support
      </sp-button>
    </div>
  </div>
  <table #table mat-table [dataSource]="users" class="sp-mt-4 mat-elevation-z0">
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username</th>
      <td mat-cell *matCellDef="let element"> {{ element.username }}</td>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef> First Name</th>
      <td mat-cell *matCellDef="let element"> {{ element.first_name }}</td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef> Last Name</th>
      <td mat-cell *matCellDef="let element"> {{ element.last_name }}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef> Role</th>
      <td mat-cell *matCellDef="let element"> {{ getRoleName(element.permission) }}</td>
    </ng-container>

    <ng-container matColumnDef="is_admin">
      <th mat-header-cell *matHeaderCellDef>Administrator</th>
      <td mat-cell *matCellDef="let element">
        @if(element.roles.includes("ClientAdmin")){
          <i class="sp-text-green-600 fa-solid fa-check"></i>
        } @else {
          <i class="sp-text-red-600 fa-solid fa-times"></i>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button [matMenuTriggerFor]="menu" class="sp-flex !sp-p-0 sp-flex-row sp-items-center sp-justify-center"
                mat-icon-button>
          <i class="fa-solid sp-text-base fa-ellipsis-vertical fa"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="editUser(element)" mat-menu-item>
            <span>Edit</span>
          </button>
          <button (click)="resetPassword(element)" mat-menu-item>
            <span>Change Password</span>
          </button>
          <mat-divider></mat-divider>
          <div [matTooltip]="element.id == currentUser.id ? 'You cannot delete yourself!' : ''">
            <button [disabled]="element.id == currentUser.id" (click)="deleteUser(element)" mat-menu-item>
              <span class="sp-text-red-800">Delete</span>
            </button>
          </div>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
