import {
  Directive,
  InjectionToken,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'

export const sp_lazy = new InjectionToken<LazyContent>('sp_lazy')

/**
 * Use 'lazyContent' directive to load template content only when its parent visible.
 * Based on the Steven.Xi's idea: https://stackoverflow.com/a/70974290/4180933
 */
@Directive({
  selector: '[lazy]',
  providers: [{ provide: sp_lazy, useExisting: LazyContent }],
  standalone: true,
})
export class LazyContent {
  private _isCreated = false
  private get _isVisible(): boolean {
    return !!this.templateRef.elementRef.nativeElement.parentElement
      ?.offsetParent
  }

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngAfterViewChecked() {
    // embed template if parent element is visible
    if (!this._isCreated && this._isVisible) {
      this._isCreated = true
      setTimeout(() => this.viewContainer.createEmbeddedView(this.templateRef))
    }
  }
}
