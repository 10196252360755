import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoadingService} from "./services/loading.service";
import {UserService} from "./services/user.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {CompanyService} from "./services/company.service";

@Component({
  selector: 'survpal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  loading = false;
  loaded = false;

  constructor(private companyService: CompanyService, private loadingService: LoadingService, private cdr: ChangeDetectorRef, private userService: UserService, private router: Router) {

  }

  ngOnInit() {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      if(this.userService.currentUser.value.id != null) {
        this.companyService.getCreditCount().subscribe(x => {
          this.companyService.creditCount.next(x);
        })
      }
    });

    this.loadingService.loading.subscribe(x => {
      this.loading = x;
      this.cdr.detectChanges();
    });
    this.loadingService.start();
    this.userService.getCurrentUser().subscribe({
      next: x => {
        this.userService.currentUser.next(x);
        this.companyService.getCreditCount().subscribe(x => {
          this.companyService.creditCount.next(x);

          if(this.router.url == "" || this.router.url == "/" || this.router.url == "login") {
            window.location.href = "/job";
            return;
          }
          this.loaded = true;
          this.loadingService.stop();
          this.cdr.detectChanges();
        })
      },
      error: e => {
        this.router.navigate(['/', ''], { queryParamsHandling: "merge"}).then(r => {
          this.loadingService.stop();
          this.loaded = true;
          this.cdr.detectChanges();
        });
      }
    });
  }
}
