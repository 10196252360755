@if (this.info != null) {
  <div class="sp-w-full" [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}">
    <div class="sp-text-2xl sp-text-sp-blue sp-w-auto">Account Information</div>

    @if (inTrial()) {
      <div class="sp-my-2 sp-bg-green-200 sp-p-2 sp-rounded sp-text-green-800 sp-flex sp-flex-row sp-items-center">
        <i class="fa-duotone fa-circle-exclamation sp-mr-3 sp-text-2xl"></i>
        <p class="!sp-my-0">
          You are currently in a free trial, no credits will be deducted or used during this period. Your free trial
          will expire on the <span
          class="sp-font-bold">{{ info?.CompanySubscription.FreeTrialEndDate | date : 'dd/MM/YYYY' }}.</span>
        </p>
      </div>
    }

    @if (!inTrial() && hasSubscription() && isCancelled()) {
      <div class="sp-my-2 sp-bg-yellow-200 sp-p-2 sp-rounded sp-text-yellow-800 sp-flex sp-flex-row sp-items-center">
        <i class="fa-duotone fa-triangle-exclamation sp-mr-3 sp-text-2xl"></i>
        <p class="!sp-my-0">
          You cancelled your plan on <span
          class="sp-font-bold">{{ info?.CurrentSubscription.CancelledAt | date : 'dd/MM/YYYY' }}</span> and your plan
          will finish on <span
          class="sp-font-bold">{{ info?.CurrentSubscription.CancellationDate | date : 'dd/MM/YYYY' }}</span>
        </p>
      </div>
    }

    @if (hasSubscription()) {
      <div class="sp-my-4 sp-flex sp-flex-row sp-items-center sp-p-4 sp-bg-stone-200 sp-rounded">
        <div>
          <div class="sp-text-xl sp-w-auto sp-mb-4 sp-min-w-[794px]">Subscription Information</div>
          @if (!isPAYG()) {
            <p>
              You're currently subscribed to the <span
              class="sp-font-bold">{{ info.CurrentSubscription.ProductName }}</span> subscription and have <span
              class="sp-font-bold">{{ availableCredits }}</span> credits remaining.
            </p>
          } @else if (isPAYG() && !inTrial()) {
            <p>
              You're currently subscribed to the <span class="sp-font-bold">Pay as you go</span> plan. Any credits you
              use over your credit balance will be charged at the end of your billing period on the <span
              class="sp-font-bold">{{ info.CurrentSubscription.EndDate | date: 'dd/MM/yyyy' }}</span>.
            </p>
          } @else if (isPAYG()) {
            <p>
              You're currently subscribed to the <span class="sp-font-bold">Pay as you go</span> plan.
            </p>
          }

          <p>
            Credits expire 3 months after date of purchase. To view a log of your credits and their expiry dates click on the “Credit Logs” tab.
          </p>

          @if (inTrial()) {
            <p>
              Credits will not be deducted or used during your free trial.
            </p>
          }

          @if (availableCredits < 0) {
            <p>
              You will automatically be charged on the <span
              class="sp-font-bold">{{ info.CurrentSubscription.EndDate | date: 'dd/MM/yyyy' }}</span> for the <span
              class="sp-font-bold">{{ Math.abs(availableCredits) }}</span> credits used
              @if (!isPAYG()) {
                over your plan allocation.
              } @else {
                this billing cycle to the account we have on file.
              }
            </p>
          }
          @if (!isPAYG()) {
            <p>
              This subscription renews <span class="sp-font-bold">{{ chargeSubText() }}</span> and will renew again on
              the
              <span class="sp-font-bold">{{ info.CurrentSubscription.EndDate | date: 'dd/MM/yyyy' }}</span>, charging to
              the account
              we have on file.
            </p>
            <p>
              You will receive <span class="sp-font-bold">{{ info?.CurrentSubscription.Credits }}
              credits {{ creditSubText() }}</span> and will receive
              credits again on
              the <span class="sp-font-bold">{{ info.CurrentSubscription.EndDate | date: 'dd/MM/yyyy' }}</span>.
            </p>
          }
          @if (info?.CompanySubscription?.AvailableCredits > 0) {
            <p>
              Unused credits will expire on <span
              class="sp-font-bold">{{ info.CurrentSubscription.EndDate | date: 'dd/MM/yyyy' }}</span>
            </p>
          }
        </div>
        <mat-divider class="sp-h-[140px] sp-pl-4" vertical="true"></mat-divider>
        <div class="sp-flex sp-flex-col sp-pl-4 sp-justify-center sp-items-center sp-text-center sp-w-[400px]">
          <p>To update your payment details or manage your subscription, press the button below to visit our payment
            provider Stripe.</p>
          <a href="/PaymentManagement/CustomerPortal">
            <sp-button class="sp-block sp-mt-2" icon="fa-duotone fa-money-check-pen">
              Manage Subscription
            </sp-button>
          </a>
        </div>
      </div>
    } @else {
      <div class="sp-text-xl sp-text-sp-green sp-w-auto sp-mt-4 sp-mb-2">Subscribe</div>
      <p>You aren't currently subscribed to SurvPal. In order to continue to use SurvPal outside of your free trial
        period you must be subscribed to a plan.<br>
        To subscribe, click the a package below to be taken to our payment provider.</p>
      <div class="sp-flex sp-mb-4 sp-flex-row sp-justify-start sp-flex-wrap">
        @for (item of info.CheckoutInfo?.Products; track item.name) {
          @if (item.Type == StripeCheckoutProduct.Subscription && item.Credits == 0) {
            <survpal-package (on-click)="subscribeToPlan($event)" class="sp-block sp-mr-4 sp-mt-4"
                             [package]="item"></survpal-package>
          }
        }
      </div>
    }

    @if (!inTrial() && hasCreditPacks() && hasSubscription() && !isCancelled()) {
      <div class="sp-text-xl sp-text-sp-green sp-w-auto sp-mt-4 sp-mb-2">Credit Packs</div>
      <div class="sp-flex sp-mb-4 sp-flex-row sp-justify-start sp-flex-wrap">
        @for (item of info.CheckoutInfo?.Products; track item.name) {
          @if (item.Type == StripeCheckoutProduct.Credits && item.ProductId != settings?.credit_product_id && item.Credits > 0) {
            <survpal-package button-text="Purchase" (on-click)="buyCredits($event)" class="sp-block sp-mr-4 sp-mt-4"
                             [package]="item"></survpal-package>
          }
        }
      </div>
    }

    @if (usage.length > 0) {
      <div class="sp-text-2xl sp-text-sp-blue sp-w-auto sp-mt-6">Credit usage in billing period</div>
      <table #table mat-table [dataSource]="usage" class="sp-mt-4 mat-elevation-z0">
        <ng-container matColumnDef="when">
          <th mat-header-cell *matHeaderCellDef> When</th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{ element.created_date + "Z" | date : 'dd/MM/yyyy' }}
            </div>
            <div class="!sp-text-xs sp-text-stone-500">{{ element.created_date + "Z" | date : 'HH:mm:ss' }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="what">
          <th mat-header-cell *matHeaderCellDef> What</th>
          <td mat-cell *matCellDef="let element"> {{ element.item }}</td>
        </ng-container>

        <ng-container matColumnDef="ao">
          <th mat-header-cell *matHeaderCellDef> Adjoining Owner Information</th>
          <td mat-cell *matCellDef="let element">
            <div class="sp-flex sp-flex-col">
              <div>{{ element.name }}</div>
              <div class="!sp-text-xs sp-text-stone-500">{{ element.address_line_1 }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="job_ref">
          <th mat-header-cell *matHeaderCellDef> Job Reference</th>
          <td mat-cell *matCellDef="let element">
            <div class="sp-flex sp-flex-col">
              <div>{{ element.jobref }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="by">
          <th mat-header-cell *matHeaderCellDef> User</th>
          <td mat-cell *matCellDef="let element">
            <div class="sp-flex sp-flex-col">
              <div>{{ element.user }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="credits">
          <th mat-header-cell *matHeaderCellDef>Credit Cost</th>
          <td mat-cell *matCellDef="let element"> {{ element.consumption }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="usageColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: usageColumns;"></tr>
      </table>

      @if (totalItemCount > 0) {
        <mat-paginator class="my-4" [length]="totalItemCount"
                       [pageSize]="perPage" (page)="toPage($event.pageIndex)">
        </mat-paginator>
      }
    }
  </div>
}

