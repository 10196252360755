<div
  class="header sp-bg-stone-200 sp-flex sp-flex-row sp-items-center sp-justify-center sp-border-b sp-border-solid sp-border-t-0 sp-border-l-0 sp-border-r-0 sp-border-stone-300">
  <div class="sp-w-[1200px] sp-p-4 sp-flex sp-flex-row sp-items-center">
    <div class="sp-flex sp-flex-row dense">
      <div class="sp-flex sp-flex-row sp-items-center">
        <a class="sp-relative" href="/job">
          <img class="sp-w-[100px] sp-mr-4"
               src="https://survpalfiles.blob.core.windows.net/public/survpal-logo-2023.svg">
        </a>
        <!--        <a href="/account/manage" class="menu-text welcome sp-ml-4 sp-text-stone-600 menu-item sp-uppercase sp-hover-green">-->
        <!--          <span>Welcome {{user.first_name}} {{user.last_name}}</span>-->
        <!--        </a>-->
      </div>
      <div class="sp-flex sp-items-center sp-flex-row">
        <div>
          <mat-form-field (focusin)="resultsOpen=true" class="sp-w-[480px]" subscriptSizing="dynamic"
                          appearance="outline">
            <input placeholder="Search Jobs" class="sp-relative sp-z-10" [formControl]="searchControl" matInput
                   type="search">
            @if (findingJobs) {
              <i class="fa-solid fa-cog fa-spin sp-text-stone-400 sp-mr-8" matSuffix></i>
            }
          </mat-form-field>
          <div #jobContainer cdk-overlay-origin></div>
          <ng-template (backdropClick)="closeResults()" [cdkConnectedOverlayHasBackdrop]="true"
                       [cdkConnectedOverlayOpen]="resultsOpen && jobResults.length > 0"
                       cdkConnectedOverlayPanelClass="context-overlay" [cdkConnectedOverlayOrigin]="jobContainer"
                       cdkConnectedOverlay>
            <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true" class="sp-overflow-hidden"
                                role="list">
              @for (item of jobResults; track item) {
                <mat-list-option (click)="goToJob(item)" role="listitem">{{ item.value }}</mat-list-option>
              }
            </mat-selection-list>
          </ng-template>
        </div>
        <a [routerLink]="['/', 'account-management', 'account-information']" matTooltip="Available Credits"
           class="sp-text-stone-600 sp-cursor-pointer sp-ml-4 sp-p-2 sp-rounded sp-bg-blend-darken"
           style="text-decoration: none !important;" matRipple>
          <div class="sp-flex sp-flex-row sp-items-center">
            <div class="pal-coin">
              <i class="fa-duotone fa-coins"></i>
            </div>
            <div [style.color]="credits < 5 ?  'red' : 'initial'" class="sp-ml-2">
              {{ credits }}
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="sp-ml-auto sp-flex sp-flex-row sp-content-center">
      <a href="/tracking" class="menu-text sp-text-stone-600 menu-item sp-uppercase sp-hover-green sp-mr-4">
        <span>Tracking</span>
      </a>
      <a href="/myactions/index" class="menu-text sp-text-stone-600 menu-item sp-uppercase sp-hover-green sp-mr-4">
        <span>My Actions</span>
      </a>
      <a href="/job" class="menu-text sp-text-stone-600 menu-item sp-uppercase sp-hover-green sp-mr-4">
        <span>Job Zone</span>
      </a>
      <a [matMenuTriggerFor]="settingsMenu" class="menu-text sp-text-stone-600 menu-item sp-uppercase sp-hover-green sp-mr-4">
        <span>Settings</span>
      </a>
      <a [matMenuTriggerFor]="menu"
         class="menu-text profile-item sp-bg-sp-green menu-item sp-uppercase">
        <span>{{ user.initials }}</span>
      </a>
      <mat-menu class="settings-menu" #settingsMenu="matMenu">
        <a href="joboptions/surveyor" mat-menu-item>
          <i class="fa-duotone fa-clipboard-list sp-text-sp-green sp-mr-3"></i>
          <span>Surveyor</span>
        </a>
        <a href="template" mat-menu-item>
          <i class="fa-duotone fa-file-signature sp-text-sp-green sp-mr-3"></i>
          <span>Templates & Clauses</span>
        </a>
        <a href="settings/defaulttemplatesettings" mat-menu-item>
          <i class="fa-duotone fa-memo-circle-info sp-text-sp-green sp-mr-3"></i>
          <span>Templates Settings</span>
        </a>
        <a href="joboptions/branding" mat-menu-item>
          <i class="fa-duotone fa-paintbrush-fine sp-text-sp-green sp-mr-3"></i>
          <span>Branding</span>
        </a>
      </mat-menu>
      <mat-menu class="admin-menu" #menu="matMenu">
        <div class="user-info sp-p-4 sp-flex flex-row sp-items-center">
          <div class="sp-mr-4">
            <a class="menu-text bigger profile-item sp-bg-sp-green menu-item sp-uppercase">
              <span>{{ user.initials }}</span>
            </a>
          </div>
          <div>
            <span class="sp-text-stone-600">{{ user.first_name }} {{ user.last_name }}</span>
            <br>
            <span class="sp-text-sm sp-text-stone-400">{{ getRoleName() }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        @if (user.roles.includes(RoleHelper.AdminRole)) {
          <a #rla1="routerLinkActive" [routerLink]="['/', 'account-management']" routerLinkActive="active"
             mat-menu-item>
            <i class="fa-duotone fa-people-roof sp-text-sp-green sp-mr-3"></i>
            <span>Account Management</span>
          </a>
        }
        <a [routerLink]="['/', 'my-details']" mat-menu-item routerLinkActive="active">
          <i class="fa-duotone fa-address-card sp-text-sp-green sp-mr-3"></i>
          <span>My Details</span>
        </a>
        <mat-divider></mat-divider>
        <button (click)="logout()" mat-menu-item>
          <i class="fa-duotone fa-right-from-bracket sp-text-sp-green sp-mr-3"></i>
          <span>Log off</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
