import {UserRoles} from "../enums/user-roles.enum";
import {UserBrand} from "./user-brand.model";

export class User {
  public id!: number | null;
  public first_name!: string;
  public last_name!: string;
  public email!: string;
  public username!: string;
  public initials!: string;
  public initials_colour!: string;
  public qualifications!: string;
  public brands!: UserBrand[];
  public roles!: string[];
  public permission!: UserRoles;
  public company_name!: string;
  public company_id!: string;
  public linked_to_job!: boolean;

  constructor() {
    this.roles = [];
    this.brands = [];
    this.id = null;
  }
}
