import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CompanyService} from "../../services/company.service";
import {LegacyDownloadSummary} from "../../models/legacy-download-summary.model";
import {spAnimations} from "../../helper/animation.helper";
import {LoadingService} from "../../services/loading.service";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatMenuModule} from "@angular/material/menu";
import {MatTableModule} from "@angular/material/table";

@Component({
  selector: 'survpal-download-summary',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatTableModule
  ],
  templateUrl: './download-summary.component.html',
  styleUrl: './download-summary.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadSummaryComponent implements OnInit {

  public items: LegacyDownloadSummary[] = [];
  public displayedColumns = ['name', 'downloads'];

  constructor(private companyService: CompanyService, private loadingService: LoadingService, private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.loadingService.start()
    this.companyService.getDownloadSummary().subscribe(x => {
      this.items = x;
      this.loadingService.stop();
      this.cdr.detectChanges();
    })
  }
}
