import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {LoginService} from "../../services/login.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoadingService} from "../../services/loading.service";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'survpal-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef, private loginService: LoginService, private snackBar: MatSnackBar, private loadingService: LoadingService, private route: ActivatedRoute, private recaptchaV3Service: ReCaptchaV3Service) {

  }

  ngOnInit() {
    let error = this.route.snapshot.queryParamMap.get("error");
    switch (error) {
      case 'no_plan':
      case 'no_credits':
      case 'no_payment':
        this.snackBar.open("There is a problem with your survpal subscription. Please contact your administrator.", '', {
          duration: 10000,
          panelClass: 'snackbar'
        });
        break;
    }
  }

  public usernameControl = new UntypedFormControl('', Validators.required);
  public passwordControl = new UntypedFormControl('', Validators.required);
  public rememberMeControl = new UntypedFormControl(false);

  login() {
    let username = this.usernameControl.value;
    let password = this.passwordControl.value;
    let remember = this.rememberMeControl.value;

    this.loadingService.start();

    this.recaptchaV3Service.execute('loginAction')
      .subscribe({
        next: (token) => {
          this.loginService.login(username, password, remember, token, window.location.href).subscribe({
              next: x => {
                if (x.result && (x.redirect?.length ?? 0) > 0) {
                  window.location.href = x.redirect as string;
                  return;
                }

                this.loadingService.stop();
                if ((x.message?.length ?? 0) > 0) {
                  this.snackBar.open(x.message, '', {
                    duration: 5000,
                    panelClass: 'login-snackbar'
                  });
                }
              },
              error: e => this.#genericError()
            }
          )
        },
        error: e => this.#genericError()
      });
    this.cdr.detectChanges();
  }

  #genericError() {
    this.loadingService.stop();
    this.snackBar.open("An error occurred whilst trying to log you in, please try again.", '', {
      duration: 5000,
      panelClass: 'login-snackbar'
    });
  }
}
