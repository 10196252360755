import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  AppWrapperContainerComponent
} from "../../components/shared/app-wrapper-container/app-wrapper-container.component";
import {MatTabsModule} from "@angular/material/tabs";
import {SpWindowComponent} from "../../components/shared/sp-window/sp-window.component";
import {ListUsersComponent} from "../list-users/list-users.component";
import {LazyContent} from "../../directives/lazy.directive";
import {AccountDetailsComponent} from "../account-details/account-details.component";
import {Router, RouterOutlet} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'survpal-account-management',
  standalone: true,
  imports: [
    AppWrapperContainerComponent,
    MatTabsModule,
    SpWindowComponent,
    ListUsersComponent,
    LazyContent,
    AccountDetailsComponent,
    RouterOutlet
  ],
  templateUrl: './account-management.component.html',
  styleUrl: './account-management.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountManagementComponent implements OnInit, OnDestroy {

  public subscriptions: Subscription[] = [];
  public activeLink: string = "account-information";

  constructor(private router: Router, private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.router.events.subscribe(x => {
      let split = this.router.url.split('/');
      this.activeLink = split[split.length - 1];
      this.cdr.detectChanges();
    });

    let split = this.router.url.split('/');
    this.activeLink = split[split.length - 1];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe())
  }

  setLink(link: string) {
    this.activeLink = link;
    this.router.navigate(['/', 'account-management', link])
    this.cdr.detectChanges();
  }
}
