import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginatorModule} from "@angular/material/paginator";
import {spAnimations} from "../../helper/animation.helper";
import {LegacyDownloadSummary} from "../../models/legacy-download-summary.model";
import {CompanyService} from "../../services/company.service";
import {LoadingService} from "../../services/loading.service";
import {MatTable, MatTableModule} from "@angular/material/table";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {DownloadLog} from "../../models/download-log.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DatePipe} from "@angular/common";
import {debounceTime, switchMap} from "rxjs";

@Component({
  selector: 'survpal-download-logs',
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    DatePipe
  ],
  templateUrl: './download-logs.component.html',
  styleUrl: './download-logs.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadLogsComponent implements OnInit  {

  public items: DownloadLog[] = [];
  public displayedColumns = ['name', 'username', 'job_ref', 'download_date'];

  public totalItemCount = 0;
  public page = 1;
  public limit = 20;

  public window = window;

  public searchControl = new FormControl();

  @ViewChild('table')
  public table!: MatTable<any>

  constructor(private companyService: CompanyService, private loadingService: LoadingService, private cdr: ChangeDetectorRef, private snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.toPage(1);
    this.searchControl.valueChanges.pipe(debounceTime(400)).subscribe(x => {
      this.toPage(1);
    })
  }

  toPage(page: number){
    this.page = page;
    this.loadingService.start();
    this.companyService.getDownloadLogs(this.searchControl.value, this.limit, page).subscribe(
      {
        next: resp => {
          this.totalItemCount = resp.total;
          this.items = resp.items;
          this.loadingService.stop();
          this.table.renderRows();
          this.cdr.detectChanges();
        },
        error: e => {
          this.loadingService.stop();
          this.snackBar.open("There was a problem fetching download logs, please try again.", '', {
            duration: 5000,
            panelClass: 'snackbar'
          });
        }
      }
    )
  }
}
