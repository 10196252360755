<app-wrapper-container>
    <survpal-window>
      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a mat-tab-link (click)="setLink('account-information')" [active]="activeLink == 'account-information' || activeLink == ''">Account Information</a>
        <a mat-tab-link (click)="setLink('credit-usage')" [active]="activeLink == 'credit-usage'">Credit Usage</a>
        <a mat-tab-link (click)="setLink('credit-logs')" [active]="activeLink == 'credit-logs'">Credit Logs</a>
        <a mat-tab-link (click)="setLink('manage-users')" [active]="activeLink == 'manage-users'">Manage Users</a>
        <a mat-tab-link (click)="setLink('download-summary')" [active]="activeLink == 'download-summary'">Download Summary</a>
        <a mat-tab-link (click)="setLink('download-logs')" [active]="activeLink == 'download-logs'">Download Logs</a>
        <a mat-tab-link (click)="setLink('account-details')" [active]="activeLink == 'account-details'">Account Details</a>
      </nav>
      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      <div class="sp-p-4">
        <router-outlet></router-outlet>
      </div>
    </survpal-window>
</app-wrapper-container>

