<div class="sp-h-[100vh] sp-flex sp-flex-col">
  <div class="sp-flex-none">
    <survpal-application-header></survpal-application-header>
  </div>
  <div [style.width]="width" class="sp-flex sp-flex-1 sp-self-center sp-p-4">
      <ng-content></ng-content>
  </div>
  <div class="sp-flex-none">
    <survpal-footer></survpal-footer>
  </div>
</div>
