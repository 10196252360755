import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./views/login/login.component";
import {RegisterComponent} from "./views/register/register.component";
import {ResetPasswordComponent} from "./views/reset-password/reset-password.component";
import {AccountManagementComponent} from "./views/account-management/account-management.component";
import {AccountDetailsComponent} from "./views/account-details/account-details.component";
import {ListUsersComponent} from "./views/list-users/list-users.component";
import {AccountInformationComponent} from "./views/account-information/account-information.component";
import {DownloadSummaryComponent} from "./views/download-summary/download-summary.component";
import {DownloadLogsComponent} from "./views/download-logs/download-logs.component";
import {CreditUsageComponent} from "./views/credit-usage/credit-usage.component";
import {MyDetailsComponent} from "./views/my-details/my-details.component";
import {CreditLogsComponent} from "./views/credit-logs/credit-logs.component";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgot-password',
    component: ResetPasswordComponent
  },
  {
    path: 'account-management',
    component: AccountManagementComponent,
    children: [
      {
        path: '',
        component: AccountInformationComponent
      },
      {
        path: 'account-information',
        component: AccountInformationComponent
      },
      {
        path: 'credit-usage',
        component: CreditUsageComponent
      },
      {
        path: 'credit-logs',
        component: CreditLogsComponent
      },
      {
        path: 'manage-users',
        component: ListUsersComponent
      },
      {
        path: 'download-summary',
        component: DownloadSummaryComponent
      },
      {
        path: 'download-logs',
        component: DownloadLogsComponent
      },
      {
        path: 'account-details',
        component: AccountDetailsComponent
      },
    ]
  },
  {
    path: 'my-details',
    component: MyDetailsComponent
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
