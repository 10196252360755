<survpal-header></survpal-header>
<div [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}" class="reset-container">
  <img class="sp-mb-8 sp-w-[400px]" src="https://survpalfiles.blob.core.windows.net/public/survpal-logo-2023.svg">
  <mat-card class="reset-box sp-p-4 sp-bg-sky-100" appearance="raised">
    <div class="sp-text-4xl sp-mb-3 sp-text-sp-blue">Reset Password or Username</div>
    <div class="sp-w-full sp-flex sp-flex-col sp-items-start">
      <div class="sp-w-full">
        <div class="sp-text-2xl sp-mb-3 sp-text-sp-green">Reset Password</div>
        <p class="sp-text-stone-400">Enter your username below to start the password reset process.</p>
        <mat-form-field class="sp-w-full" appearance="outline">
          <mat-label>
            Username
          </mat-label>
          <input [formControl]="usernameControl" type="text" class="sp-h-[42px]" matInput>
        </mat-form-field>
      </div>
      <div class="sp-flex sp-flex-row sp-justify-end sp-w-full">
        <div class="sp-bg-sp-green sp-rounded sp-min-w-[96px]"
             [matTooltip]="usernameControl.invalid ? 'Please fill in your username to reset your password' : ''"
             [ngClass]="{'sp-opacity-60 sp-cursor-not-allowed': usernameControl.invalid}">
          <button [disabled]="usernameControl.invalid" type="button" (click)="resetPassword()" mat-button
                  class="sp-w-full">
            <i class="fa-duotone fa-lock-keyhole-open sp-mr-2 sp-text-white"></i>
            <span class="sp-text-white">Reset Password</span>
          </button>
        </div>
      </div>
      <div class="sp-my-4">
        <mat-divider></mat-divider>
      </div>
      <div class="sp-text-2xl sp-mb-3 sp-text-sp-green">Retrieve Username</div>
      <p class="sp-text-stone-400">Forgot your username? Please provide a registered email address below.</p>
      <mat-form-field class="sp-w-full" appearance="outline">
        <mat-label>
          Email
        </mat-label>
        <input type="email" [formControl]="emailControl" class="sp-h-[42px]" matInput>
      </mat-form-field>
      <div class="sp-flex sp-flex-row sp-justify-end sp-w-full">
        <div class="sp-bg-sp-green sp-rounded sp-min-w-[96px]"
             [matTooltip]="emailControl.invalid ? 'Please fill in your registered email address' : ''"
             [ngClass]="{'sp-opacity-60 sp-cursor-not-allowed': emailControl.invalid}">
          <button [disabled]="emailControl.invalid" type="button" (click)="getUsername()" mat-button
                  class="sp-w-full">
            <i class="fa-duotone fa-user-tie sp-mr-2 sp-text-white"></i>
            <span class="sp-text-white">Retrieve Username</span>
          </button>
        </div>
      </div>
    </div>
  </mat-card>
</div>
<survpal-footer></survpal-footer>
