import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {User} from "../models/user.model";
import {BehaviorSubject, map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient) {

  }

  public requestSupport(message: string) {
    return this.http.post(`Registration/SendSupportRequest`, {additionalMessage: message});
  }
}



