<div class="header sp-bg-stone-200 sp-flex sp-flex-row sp-items-center sp-justify-center sp-border-b sp-border-solid sp-border-t-0 sp-border-l-0 sp-border-r-0 sp-border-stone-300">
  <div class="sp-w-[1200px] sp-p-4 sp-flex sp-flex-row sp-items-center">
    <div class="sp-flex sp-flex-row sp-items-start sp-w-auto sp-flex-wrap">
      <a href="https://survpal.co.uk">
        <img class="sp-w-[150px]" src="https://survpalfiles.blob.core.windows.net/public/survpal-logo-2023.svg">
      </a>
    </div>
    <div class="menu sp-flex sp-flex-row sp-items-center sp-mx-auto">
      <a href="https://survpal.co.uk" class="sp-text-stone-600 menu-item sp-hover-green">
        <span>Home</span>
      </a>
      <a href="https://survpal.co.uk/what-is-survpal/" class="sp-text-stone-600 menu-item sp-hover-green">
        <span>What is SurvPal?</span>
      </a>
      <a href="https://survpal.co.uk/who-is-it-for/" class="sp-text-stone-600 menu-item sp-hover-green">
        <span>Who is it for?</span>
      </a>
      <a href="https://survpal.co.uk/prices/" class="sp-text-stone-600 menu-item sp-hover-green">
        <span>Prices</span>
      </a>
      <a href="https://survpal.co.uk/about-us/" class="sp-text-stone-600 menu-item sp-hover-green">
        <span>About us</span>
      </a>
      <a href="https://survpal.co.uk/contact-us/" class="sp-text-stone-600 menu-item sp-hover-green">
        <span>Contact</span>
      </a>
    </div>
    <div class="sp-bg-sp-green sp-rounded sp-mr-2 sp-min-w-[96px]">
      <a [routerLink]="['/']" class="sp-w-full" mat-button>
        <span class="sp-text-white">
           <i class="sp-text-white fa-duotone fa-sign-in sp-mr-2"></i>
          Log in
        </span>
      </a>
    </div>
    <div class="sp-bg-sp-green sp-rounded sp-min-w-[96px]">
      <button [routerLink]="['/', 'register']" mat-button class="sp-w-full">
        <i class="sp-text-white fa-duotone fa-feather-pointed sp-mr-2"></i>
        <span class="sp-text-white">Sign up</span>
      </button>
    </div>
  </div>
</div>
