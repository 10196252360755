<div class="sp-p-4">
  <div class="sp-text-2xl sp-text-sp-blue">{{data.title}}</div>
  <div class="sp-my-3">
    <mat-divider></mat-divider>
  </div>
  <div>
    <p class="sp-text-slate-600 sp-my-4">{{data.message}}</p>
    <div class="sp-flex sp-flex-col">
      <mat-form-field appearance="outline" class="sp-w-full">
        <mat-label>Additional Information</mat-label>
        <textarea [formControl]="control"  cdkTextareaAutosize
                  cdkAutosizeMinRows="20"
                  cdkAutosizeMaxRows="30" name="message" matInput></textarea>
      </mat-form-field>
    </div>
    <div class="sp-flex sp-flex-row sp-items-center sp-justify-end">
      <sp-button class="sp-mr-2" icon="fa-solid fa-times" (sp-click)="cancel()" colour="red">
        Cancel
      </sp-button>
      <sp-button class="sp-ml-2" icon="fa-solid fa-check" (sp-click)="confirm()" colour="green">
        Send Support Request
      </sp-button>
    </div>
  </div>
</div>


