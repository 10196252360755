import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {RegisterResponseModel} from "../models/register-response.model";
import {RegistrationModel} from "../models/registration.model";

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient, private router: Router, route: ActivatedRoute) {

  }

  public register(model: RegistrationModel) {
    return this.http.post<RegisterResponseModel>("/register/company", model);
  }

  public isRegistered(name: string, postcode: string){
    return this.http.post<boolean>("/register/company-name-validation", {
      CompanyName: name,
      Postcode: postcode
    });
  }
}
