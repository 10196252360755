import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './views/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FooterComponent} from './components/shared/footer/footer.component';
import {HeaderComponent} from './components/shared/header/header.component';
import {RegisterComponent} from './views/register/register.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDividerModule} from "@angular/material/divider";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {MatDialogModule} from "@angular/material/dialog";
import {ConfirmComponent} from './dialogs/confirm/confirm.component';
import {SpButtonComponent} from "./components/shared/sp-button/sp-button.component";

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        RegisterComponent,
        ResetPasswordComponent,
        ConfirmComponent
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatSlideToggleModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        MatDividerModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule,
        RecaptchaV3Module,
        FooterComponent,
        SpButtonComponent], providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcDjyopAAAAAM5_zbMICByEiZrXaT4QFdzkk3kx' },
        { provide: LOCALE_ID, useValue: "en-GB" },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
