export class MyDetails {
  public UserID!: number;
  public FirstName!: string;
  public LastName!: string;
  public MobilePhone!: string;
  public Fax!: string;
  public Email!: string;
  public Qualification!: string;
  public Initials!: string;
  public InitialColour!: string
  public KanBanColorCodeVisibility!: boolean;
  public ShowInitials!: boolean;
  public ShowBrandInitials!: boolean;
  public EmailUpdates!: boolean;
  public EnableEmailReminders!: boolean;
  public UserGUID!: string;

  public Group!: string[]
  public LinkedBrandList!: MyDetailsBrandList[]

  constructor() {
    this.Group = [];
    this.LinkedBrandList = [];
  }
}

export class MyDetailsBrandList {
  public ID!: string;
  public Name!: string;
  public IsChecked!: boolean;
}
