import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatTable, MatTableModule} from "@angular/material/table";
import {MatRippleModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {RouterLinkActive} from "@angular/router";
import {MatListModule} from "@angular/material/list";
import {MatDialog} from "@angular/material/dialog";
import {AddEditUserComponent} from "../../dialogs/add-edit-user/add-edit-user.component";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";
import {LoadingService} from "../../services/loading.service";
import {UserService} from "../../services/user.service";
import {User} from "../../models/user.model";
import {ConfirmComponent} from "../../dialogs/confirm/confirm.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SupportService} from "../../services/support.service";
import {SupportRequestComponent} from "../../dialogs/support-request/support-request.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Subscription} from "rxjs";
import {spAnimations} from "../../helper/animation.helper";
import {getRoleName, UserRoles} from "../../enums/user-roles.enum";

@Component({
  selector: 'survpal-list-users',
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatRippleModule,
    MatMenuModule,
    RouterLinkActive,
    MatListModule,
    SpButtonComponent,
    MatTooltipModule
  ],
  templateUrl: './list-users.component.html',
  styleUrl: './list-users.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListUsersComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = ["username", "first_name", "last_name", "role", "is_admin", "actions"];
  public users: User[] = [];

  public subscriptions: Subscription[] = [];
  public currentUser = this.userService.currentUser.value;

  @ViewChild('table')
  public table!: MatTable<any>

  constructor(private cdr: ChangeDetectorRef, private dialog: MatDialog, private loadingService: LoadingService, private snackBar: MatSnackBar,
              private userService: UserService, private supportService: SupportService) {
    this.loadingService.start();
  }

  ngOnInit() {
    this.userService.getUsers().subscribe(x => {
      this.users = x;
      this.loadingService.stop();
      this.cdr.detectChanges();
    })

    this.subscriptions.push(this.userService.updateUser.subscribe(x => {
      if (!this.users.map(x => x.id).includes(x.id)) {
        this.users.push(x);
      } else {
        let index = this.users.findIndex(u => u.id == x.id);
        this.users.splice(index, 1, x);
      }
      this.table.renderRows();
      this.cdr.detectChanges();
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  addUser() {
    const dialogRef = this.dialog.open(AddEditUserComponent, {
      width: '800px',
      disableClose: true,
      data: {
        title: `Add User`,
        message: `Add a new user to survpal`
      },
      panelClass: 'add-edit-user'
    });
    dialogRef.afterClosed().subscribe(x => {

    });
  }

  editUser(user: User) {
    const dialogRef = this.dialog.open(AddEditUserComponent, {
      width: '800px',
      disableClose: true,
      data: {
        title: `Edit ${user.first_name} ${user.last_name}`,
        message: ``,
        id: user.id
      },
      panelClass: 'add-edit-user'
    });
    dialogRef.afterClosed().subscribe(x => {

    });
  }

  deleteUser(user: User) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '400px',
      disableClose: true,
      data: {
        title: `Are you sure?`,
        message: `Are you sure you want to delete this user: '${user.first_name} ${user.last_name}'`
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.loadingService.start();
        this.userService.delete(user.id as number).subscribe({
          next: val => {
            this.loadingService.stop();
            this.users = this.users.filter(x => x.id != user.id);
            this.cdr.detectChanges();
          },
          error: e => {
            this.loadingService.stop();
            this.snackBar.open(`Something went wrong when trying to delete '${user.first_name} ${user.last_name}', please try again.`, '', {
              duration: 5000,
              panelClass: 'snackbar'
            });
          }
        })
      }
    });
  }

  requestSupport() {
    const dialogRef = this.dialog.open(SupportRequestComponent, {
      width: '800px',
      data: {
        title: `Request Support`,
        message: `This will create a new user called Support-${this.currentUser.company_name} with full access to your account to enable our support team to help you. You can revoke access at any time.'`
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x !== false) {
        this.loadingService.start();
        this.supportService.requestSupport(x).subscribe({
          next: x => {
            this.userService.getUsers().subscribe(x => {
              this.loadingService.stop();
              this.users = x;
              this.cdr.detectChanges();
              this.snackBar.open("A support access request has been sent!", '', {
                duration: 5000,
                panelClass: 'snackbar'
              })
            })
          },
          error: (e: any) => {
            this.loadingService.stop();
            if (e.status == 400) {
              this.snackBar.open("Something went wrong when trying to send the support email to Survpal, please try again.", '', {
                duration: 5000,
                panelClass: 'snackbar'
              });
            } else if (e.status == 409) {
              this.snackBar.open("This company already has an active support user.", '', {
                duration: 5000,
                panelClass: 'snackbar'
              });
            } else {
              this.snackBar.open("An error occurred whilst trying to request support, please try again.", '', {
                duration: 5000,
                panelClass: 'snackbar'
              });
            }
          }
        });
      }
    });
  }

  resetPassword(user: User){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '400px',
      disableClose: true,
      data: {
        title: `Are you sure?`,
        message: `Do you want to reset the password for '${user.first_name} ${user.last_name}'?`
      }
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.loadingService.start();
        this.userService.changePassword(user).subscribe({
          next: x => {
            this.loadingService.stop();
            this.snackBar.open("A new password has been sent to the user's email address.", '', {
              duration: 5000,
              panelClass: 'snackbar'
            });
          },
          error: e => {
            this.loadingService.stop();
            switch(e.status) {
              case 403:
                this.snackBar.open("Access denied.", '', {
                  duration: 5000,
                  panelClass: 'snackbar'
                });
                break;
              case 404:
                this.snackBar.open("The user was not found, please refresh the page and try again.", '', {
                  duration: 5000,
                  panelClass: 'snackbar'
                });
                break;
              default:
                this.snackBar.open("An error occurred whilst trying change this users password, please try again.", '', {
                  duration: 5000,
                  panelClass: 'snackbar'
                });
                break;
            }
          }
        })
      }
    });
  }

  hasSupport() {
    return this.users.find(x => x.username == `Support-${this.currentUser.company_name.replaceAll(" ", "_")}`) != null;
  }

  getRoleName(role: UserRoles) {
    return getRoleName(role);
  }
}
