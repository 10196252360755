import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CompanyService} from "../../services/company.service";
import {spAnimations} from "../../helper/animation.helper";
import {LoadingService} from "../../services/loading.service";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatMenuModule} from "@angular/material/menu";
import {MatTable, MatTableModule} from "@angular/material/table";
import {CreditUsageReportObject} from "../../models/credit-usage-report.model";
import {DatePipe} from "@angular/common";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";

@Component({
  selector: 'survpal-credit-usage',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatTableModule,
    DatePipe,
    MatPaginatorModule,
    SpButtonComponent
  ],
  templateUrl: './credit-usage.component.html',
  styleUrl: './credit-usage.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditUsageComponent implements OnInit {

  public usage: CreditUsageReportObject[] = [];
  public usageColumns = ['when', 'what', 'ao', 'job_ref', 'by', 'credits'];
  public totalItemCount = 0;
  public perPage = 20;
  public page = 0;

  @ViewChild('table')
  public table!: MatTable<any>

  constructor(private companyService: CompanyService, private loadingService: LoadingService, private cdr: ChangeDetectorRef, private snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.loadingService.start();
    this.page = 0;
    this.companyService.getAccountCreditUsage(this.perPage, 0, "", "").subscribe(x => {
      this.usage = x;
      this.companyService.getTotalAccountCreditUsage("", "").subscribe(x => {
        this.totalItemCount = x;
        this.loadingService.stop();
        this.cdr.detectChanges();
      });
    });
  }

  toPage(page: number){
    this.page = page;
    this.loadingService.start();
    this.companyService.getAccountCreditUsage(this.perPage, this.perPage * this.page, "", "").subscribe(
      {
        next: resp => {
          this.usage = resp;
          this.loadingService.stop();
          this.table.renderRows();
          this.cdr.detectChanges();
        },
        error: e => {
          this.loadingService.stop();
          this.snackBar.open("There was a problem fetching credit usage logs, please try again.", '', {
            duration: 5000,
            panelClass: 'snackbar'
          });
        }
      }
    )
  }
}
