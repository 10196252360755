import {Component, Input} from '@angular/core';
import {ApplicationHeaderComponent} from "../application-header/application-header.component";
import {FooterComponent} from "../footer/footer.component";

@Component({
  selector: 'app-wrapper-container',
  standalone: true,
  imports: [
    ApplicationHeaderComponent,
    FooterComponent,
  ],
  templateUrl: './app-wrapper-container.component.html',
  styleUrl: './app-wrapper-container.component.scss'
})
export class AppWrapperContainerComponent {
  @Input('width')
  width: string = "1200px";
}
