<survpal-header></survpal-header>
<form class="login-container">
  <img class="sp-mb-8 sp-w-[400px] animate__animated animate__fadeInUp" src="https://survpalfiles.blob.core.windows.net/public/survpal-logo-2023.svg">
  <mat-card class="login-box sp-p-4 sp-bg-sky-100" appearance="raised">
    <div class="sp-text-4xl sp-mb-3 sp-text-sp-blue">Log In</div>
    <div class="sp-text-slate-400 sp-mb-4">
      Don't have an account? Please register <a class="sp-text-sp-blue" [routerLink]="['/', 'register']">here</a>
    </div>
    <div class="sp-w-full sp-mb-2">
      <div class="sp-h-[42px] sp-rounded sp-flex sp-flex-row sp-overflow-hidden sp-border-solid sp-border sp-border-stone-200">
        <div class="sp-w-[42px] sp-flex sp-bg-stone-200 sp-flex-row sp-justify-center sp-items-center">
          <i class="fa-duotone fa-user-tie sp-text-stone-600"></i>
        </div>
        <input placeholder="Username"
               [formControl]="usernameControl"
               class="sp-outline-0 sp-px-2 sp-pt-[4px] sp-border-0 sp-h-full sp-bg-stone-100 sp-w-[100%] sp-placeholder-stone-400 sp-text-stone-600 sp-text-[16px]"
               type="text">
      </div>
    </div>
    <div class="sp-w-full sp-mb-4">
      <div class="sp-h-[42px] sp-rounded sp-flex sp-flex-row sp-overflow-hidden sp-border-solid sp-border sp-border-stone-200">
        <div class="sp-w-[42px] sp-flex sp-bg-stone-200 sp-flex-row sp-justify-center sp-items-center">
          <i class="fa-duotone fa-key sp-text-stone-600"></i>
        </div>
        <input placeholder="Password"
               [formControl]="passwordControl"
               class="sp-outline-0 sp-px-2 sp-pt-[4px] sp-border-0 sp-h-full sp-bg-stone-100 sp-w-[100%] sp-placeholder-stone-400 sp-text-stone-600 sp-text-[16px]"
               type="password">
      </div>
    </div>
    <div class="sp-text-slate-400 sp-mb-4">
      Forgotten username or password? Please reset <a class="sp-text-sp-blue" [routerLink]="['/', 'forgot-password']">here</a>
    </div>
    <div class="sp-flex sp-flex-row sp-items-center">
      <mat-checkbox [formControl]="rememberMeControl">
        <span class="sp-text-slate-400">Remember me</span>
      </mat-checkbox>
      <div class="sp-ml-auto sp-bg-sp-green sp-rounded sp-min-w-[96px]"  [matTooltip]="usernameControl.invalid || passwordControl.invalid ? 'Make sure you input a username and password before logging in' : ''" [ngClass]="{'sp-opacity-60 sp-cursor-not-allowed': usernameControl.invalid || passwordControl.invalid}">
        <button (click)="login()" mat-button class="sp-w-full" [disabled]="usernameControl.invalid || passwordControl.invalid">
          <i class="sp-text-white fa-duotone fa-sign-in sp-mr-2"></i>
          <span class="sp-text-white">Log in</span>
        </button>
      </div>
    </div>
  </mat-card>
</form>
<survpal-footer></survpal-footer>
