import { Component } from '@angular/core';
import {MatCardModule} from "@angular/material/card";

@Component({
  selector: 'survpal-window',
  standalone: true,
  imports: [
    MatCardModule
  ],
  templateUrl: './sp-window.component.html',
  styleUrl: './sp-window.component.scss'
})
export class SpWindowComponent {

}
