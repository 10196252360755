import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatRippleModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {NgClass} from "@angular/common";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'sp-button',
  standalone: true,
  imports: [
    MatRippleModule,
    MatButtonModule,
    NgClass,
    MatTooltipModule
  ],
  templateUrl: './sp-button.component.html',
  styleUrl: './sp-button.component.scss'
})
export class SpButtonComponent {
  @Input('icon')
  public icon: string = "";

  @Input('colour')
  public colour: 'green' | 'blue' | 'red' = 'green';

  @Input('disabled')
  public disabled = false;

  @Input('tooltip')
  public tooltip = "";

  @Input('type')
  public type: string = "";

  @Output('sp-click')
  public click = new EventEmitter();

  public colorClass = () => {
    switch (this.colour) {
      case "blue":
        return 'sp-bg-sp-blue';
      case "red":
        return 'sp-bg-sp-red'
      case "green":
      default:
        return 'sp-bg-sp-green'
    }
  }

  handleClick() {
    this.click.emit();
  }
}
