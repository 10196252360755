<div class="sp-p-4">
  <div class="sp-text-2xl sp-mb-3 sp-text-sp-blue">{{data.title}}</div>
  <div>
    <p class="sp-text-slate-600 sp-my-4">{{data.message}}</p>
    <div class="sp-flex sp-flex-row sp-items-center sp-justify-end">
      <sp-button class="sp-mr-2" icon="fa-solid fa-times" (sp-click)="cancel()" colour="red">
        Cancel
      </sp-button>
      <sp-button class="sp-ml-2" icon="fa-solid fa-check" [mat-dialog-close]="true" colour="green">
        OK
      </sp-button>
    </div>
  </div>
</div>


