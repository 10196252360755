import {MatSnackBar} from "@angular/material/snack-bar";

export function copyToClipboard(value: string, snack: MatSnackBar) {
  const el = document.createElement('textarea');
  el.value = value;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.zIndex = '-1';
  el.style.opacity = "0";
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  snack.open("Copied to clipboard", '', {
    duration: 5000,
    panelClass: 'snackbar'
  });
}
