import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SpButtonComponent} from "../sp-button/sp-button.component";
import {BillingPeriod, StripeCheckoutProductModel} from "../../../models/account-information.model";

@Component({
  selector: 'survpal-package',
  standalone: true,
  imports: [
    SpButtonComponent
  ],
  templateUrl: './package.component.html',
  styleUrl: './package.component.scss'
})
export class PackageComponent {
  @Input('package')
  public model!: StripeCheckoutProductModel;

  @Input('button-text')
  public buttonText = "Subscribe"

  @Output('on-click')
  public onClick: EventEmitter<StripeCheckoutProductModel> = new EventEmitter<StripeCheckoutProductModel>();

  public creditSubText = () => {
    switch (this.model.BillingPeriod) {
      case BillingPeriod.OneOff:
        return '';
      case BillingPeriod.Days:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'days' : 'day'}`
      case BillingPeriod.Weeks:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'weeks' : 'week'}`
      case BillingPeriod.Months:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'months' : 'month'}`
      case BillingPeriod.Years:
      default:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'years' : 'year'}`
    }
  }

  public chargeSubText = () => {
    switch (this.model.BillingPeriod) {
      case BillingPeriod.OneOff:
        return 'one off payment';
      case BillingPeriod.Days:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'days' : 'day'}`
      case BillingPeriod.Weeks:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'weeks' : 'week'}`
      case BillingPeriod.Months:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'months' : 'month'}`
      case BillingPeriod.Years:
      default:
        return `${this.model.BillingPeriodCount > 1 ? 'every' : 'per'}${this.model.BillingPeriodCount > 1 ? ' ' + this.model.BillingPeriodCount : ''} ${this.model.BillingPeriodCount > 1 ? 'years' : 'year'}`
    }
  }

  click() {
    this.onClick.emit(this.model);
  }
}
