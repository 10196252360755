<div [matTooltip]="tooltip">
  <button [type]="type" matRipple [disabled]="disabled" (click)="handleClick()" [class]="colorClass()" [ngClass]="{'sp-rounded sp-min-w-[96px]' : true}">
    <div class="sp-w-full">
        <span class="sp-text-white">
            @if (icon.length > 0) {
              <i [class]="icon" [ngClass]="{'sp-text-white sp-mr-2': true}"></i>
            }
          <ng-content></ng-content>
        </span>
    </div>
  </button>
</div>
