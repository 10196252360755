<survpal-header></survpal-header>
<form [formGroup]="formGroup" #form="ngForm" class="register-container"
      [@animate]="{value:'*',params:{delay:'50ms',x:'-25px'}}">
  <img class="sp-mb-8 sp-w-[400px]" src="https://survpalfiles.blob.core.windows.net/public/survpal-logo-2023.svg">
  <mat-card class="register-box sp-p-4 sp-bg-sky-100" appearance="raised">
    <div class="sp-text-4xl sp-mb-3 sp-text-sp-blue">Register</div>
    <div class="sp-text-slate-400 sp-mb-4">
      Welcome to SurvPal registration. The details that you provide below will be used to generate all your documents.
      It is therefore important that you carefully enter this information ensuring that all words are correctly
      capitalised and there are no typing errors – exactly the way you want them to appear!
    </div>
    <div class="sp-w-full sp-flex sp-flex-row sp-items-start">
      <div class="sp-w-full">
        <div class="sp-w-full sp-mb-2 sp-mr-4">
          <div class="sp-text-2xl sp-mb-3 sp-text-sp-green">Company Details</div>
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Company Name
            </mat-label>
            <input formControlName="company_name" type="text" matInput>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              House Name/Flat Number
            </mat-label>
            <input formControlName="house_name" type="text" matInput>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <div class="sp-flex flex-row sp-items-center">
            <div class="sp-w-[80px] sp-mr-4">
              <mat-form-field class="sp-w-full" appearance="outline">
                <mat-label>
                  No
                </mat-label>
                <input formControlName="house_no" type="text" matInput>
              </mat-form-field>
            </div>
            <div class="sp-w-full">
              <mat-form-field class="sp-w-full" appearance="outline">
                <mat-label>
                  Street Name
                </mat-label>
                <input formControlName="street_name" type="text" matInput>
                <mat-hint class="animate__animated animate__fadeIn" *ngIf="streetNameField?.invalid && (streetNameField?.dirty || streetNameField?.touched)">
                  <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                         *ngIf="streetNameField?.errors?.['required']">
                    Street name is required.
                  </small>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Address Line 2
            </mat-label>
            <input formControlName="address_line_2" type="text" matInput>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Town/City
            </mat-label>
            <input formControlName="city" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn" *ngIf="cityField?.invalid && (cityField?.dirty || cityField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="cityField?.errors?.['maxlength']">
                Town/City cannot be greater than {{cityField?.errors ? cityField?.errors['maxlength'].requiredLength : 0}}
                characters, (Current
                length: {{cityField?.errors ? cityField?.errors['maxlength'].actualLength : 0}})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              County
            </mat-label>
            <input formControlName="county" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn" *ngIf="countyField?.invalid && (countyField?.dirty || countyField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="countyField?.errors?.['maxlength']">
                County cannot be greater
                than {{countyField?.errors ? countyField?.errors['maxlength'].requiredLength : 0}} characters, (Current
                length: {{countyField?.errors ? countyField?.errors['maxlength'].actualLength : 0}})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Postcode
            </mat-label>
            <input formControlName="post_code" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn" *ngIf="postcodeField && postcodeField.invalid && (postcodeField.dirty || postcodeField.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="postcodeField.errors['maxlength']">
                Post code cannot be greater
                than {{postcodeField.errors['maxlength'].requiredLength}} characters,
                (Current
                length: {{postcodeField.errors['maxlength'].actualLength}})
              </small>
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="postcodeField.errors?.pattern">
                Please enter a valid PostCode
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Contact Number
            </mat-label>
            <input formControlName="contact_number" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn"
              *ngIf="contactNumberField?.invalid && (contactNumberField?.dirty || contactNumberField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="contactNumberField?.errors?.['maxlength']">
                Contact number cannot be greater
                than {{contactNumberField?.errors ? contactNumberField?.errors['maxlength'].requiredLength : 0}}
                characters, (Current
                length: {{contactNumberField?.errors ? contactNumberField?.errors['maxlength'].actualLength : 0}})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="sp-w-full sp-ml-4">
        <div class="sp-w-full sp-mb-2">
          <div class="sp-text-2xl sp-mb-3 sp-text-sp-green">User Details</div>
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Email
            </mat-label>
            <input formControlName="email" type="email" matInput>
            <mat-hint class="animate__animated animate__fadeIn" *ngIf="emailField?.invalid && (emailField?.dirty || emailField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="emailField?.errors?.['required']">
                Email is required.
              </small>
              <small class="small animated fadeIn faster red d-block mt-2 mb-2" *ngIf="emailField?.errors?.['email']">
                Email needs to be a valid email address
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              First Name
            </mat-label>
            <input formControlName="first_name" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn" *ngIf="firstNameField?.invalid && (firstNameField?.dirty || firstNameField?.touched)">
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="firstNameField?.errors?.['required']">
                First name is required.
              </small>
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="firstNameField?.errors?.['maxlength']">
                Name cannot be greater
                than {{firstNameField?.errors ? firstNameField?.errors['maxlength'].requiredLength : 0}} characters,
                (Current
                length: {{firstNameField?.errors ? firstNameField?.errors['maxlength'].actualLength : 0}})
              </small>
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="firstNameField?.errors?.['minlength']">
                Name cannot be less
                than {{firstNameField?.errors ? firstNameField?.errors['minlength'].requiredLength : 0}} characters,
                (Current
                length: {{firstNameField?.errors ? firstNameField?.errors['minlength'].actualLength : 0}})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Last Name
            </mat-label>
            <input formControlName="last_name" type="text" matInput>
            <mat-hint class="animate__animated animate__fadeIn" *ngIf="lastNameField?.invalid && (lastNameField?.dirty || lastNameField?.touched)">
              <small class="small animated red fadeIn faster d-block mt-2 mb-2"
                     *ngIf="lastNameField?.errors?.['required']">
                Last name is required.
              </small>
              <small class="small animated red fadeIn faster d-block mt-2 mb-2"
                     *ngIf="lastNameField?.errors?.['maxlength']">
                Name cannot be greater
                than {{lastNameField?.errors ? lastNameField?.errors['maxlength'].requiredLength : 0}} characters,
                (Current
                length: {{lastNameField?.errors ? lastNameField?.errors['maxlength'].actualLength : 0}})
              </small>
              <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                     *ngIf="lastNameField?.errors?.['minlength']">
                Name cannot be less than {{lastNameField?.errors ? lastNameField?.errors['minlength'].requiredLength : 0}}
                characters, (Current
                length: {{lastNameField?.errors ? lastNameField?.errors['minlength'].actualLength : 0}})
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Qualifications
            </mat-label>
            <input formControlName="qualifications" type="text" matInput>
          </mat-form-field>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Password
            </mat-label>
            <input formControlName="password" type="password" matInput>
          </mat-form-field>
          <ng-container *ngIf="passwordField?.invalid && (passwordField?.dirty || passwordField?.touched)">
            <ng-container *ngIf="passwordField.errors?.['passwordStrength']">
              <small class="sp-block small animate__animated animate__fadeIn red d-block mt-1 mb-2">
                The password must be a strong password, consisting of at least: <br>
              </small>
              <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                One UPPERCASE character <i [ngClass]="getUpperCaseCheckClass()"></i>
              </small>
              <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                One lowercase character <i [ngClass]="getLowerCaseCheckClass()"></i>
              </small>
              <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                One number character <i [ngClass]="getNumberCaseCheckClass()"></i>
              </small>
              <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                One special character <i [ngClass]="getSpecialCaseCheckClass()"></i>
              </small>
            </ng-container>
            <small class="small animate__animated animate__fadeIn red d-block mt-2 mb-2"
                   *ngIf="passwordField.errors?.['maxlength']">
              Password cannot be greater
              than {{passwordField?.errors ? passwordField.errors['maxlength'].requiredLength : 0}} characters,
              (Current
              length: {{passwordField.errors ? passwordField.errors['maxlength'].actualLength : 0}})
            </small>
            <small class="small animate__animated animate__fadeIn red d-block mt-2 mb-2"
                   *ngIf="passwordField.errors?.['minlength']">
              Password cannot be less
              than {{passwordField.errors ? passwordField.errors['minlength'].requiredLength : 0}} characters, (Current
              length: {{passwordField.errors ? passwordField.errors?.['minlength'].actualLength : 0}})
            </small>
          </ng-container>
        </div>
        <div class="sp-w-full sp-mb-2">
          <mat-form-field class="sp-w-full" appearance="outline">
            <mat-label>
              Confirm Password
            </mat-label>
            <input formControlName="confirm_password" type="password" class="sp-h-[42px]" matInput>
            <mat-hint class="animate__animated animate__fadeIn" *ngIf="password2Field?.errors?.['passwordMatch']">
              <small class="small red d-block mt-2 mb-2">
                Confirm password field must match the previously given password field
              </small>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="sp-w-full sp-mb-4">
      <mat-slide-toggle #termsSlider [formControl]="termsField">
        <span class="sp-text-stone-600 sp-ml-2">
           I have read and accept the SurvPal <a class="sp-text-sp-blue" href="https://survpal.co.uk/terms-conditions"
                                                 target="_blank">Terms and Conditions</a>
                and <a class="sp-text-sp-blue" href="https://survpal.co.uk/privacy-policy" target="_blank">Privacy Policy</a>
        </span>
      </mat-slide-toggle>
    </div>
    <div class="sp-w-full sp-mb-4">
      <mat-slide-toggle [formControl]="permissionField">
        <span class="sp-text-stone-600 sp-ml-2">
          I would like to hear about the latest updates and news from SurvPal
        </span>
      </mat-slide-toggle>
    </div>
    <div class="sp-flex sp-flex-row sp-justify-end">
      <div class="sp-bg-sp-green sp-rounded sp-min-w-[96px]" [matTooltip]="form.invalid ? 'Please complete the above form to register an account' : ''" [ngClass]="{'sp-opacity-60 sp-cursor-not-allowed': form.invalid || !termsSlider.checked}">
        <button [disabled]="form?.invalid || !termsSlider.checked" type="button" (click)="save()" mat-button class="sp-w-full">
          <i class="sp-text-white fa-duotone fa-feather-pointed sp-mr-2"></i>
          <span class="sp-text-white">Register</span>
        </button>
      </div>
    </div>
  </mat-card>
</form>
<survpal-footer></survpal-footer>
