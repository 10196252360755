<div class="sp-w-[220px] sp-rounded sp-h-full sp-flex sp-flex-col sp-text-center sp-bg-stone-200 sp-p-4">
  <div class="sp-text-xl sp-w-auto sp-mb-4 sp-text-sp-blue">{{model.ProductName}}</div>
  @if(model.Credits > 0) {
    <p>{{model.Credits}} credits {{creditSubText()}}</p>
  } @else {
    <p>You will be charged based on your usage</p>
  }
  @if(model.Price > 0) {
    <p>£{{(model.Price / 100).toFixed(2)}} {{chargeSubText()}}</p>
  }
  <sp-button (sp-click)="click()" class="sp-block sp-mt-auto" icon="fa-duotone fa-cart-shopping">
    {{buttonText}}
  </sp-button>
</div>
