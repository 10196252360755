import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {User} from "../models/user.model";
import {BehaviorSubject, map, Subject} from "rxjs";
import {LegacySaveUser} from "../models/legacy-save-user.model";
import {MyDetails} from "../models/my-details.model";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public updateUser: Subject<User> = new Subject<User>();
  public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  constructor(private http: HttpClient) {

  }

  public resetPassword(username: string, token: string) {
    return this.http.post<string>("/account/reset-password", {
      username: username,
      token: token
    });
  }

  public updatePassword(oldPassword: string, newPassword: string) {
    return this.http.post<string>("/account/update-password", {
      OldPassword: oldPassword,
      NewPassword: newPassword
    });
  }

  public getUsername(email: string, token: string) {
    return this.http.post<string>("/account/get-username", {
      email: email,
      token: token
    });
  }

  public getCurrentUserDetails() {
    return this.http.get<MyDetails>("account/current-user-details");
  }

  public setCurrentUserDetails(model: MyDetails) {
    return this.http.post("account/EditUsersDetails", {
      model: model,
      brandList: model.LinkedBrandList
    });
  }

  public getCurrentUser() {
    return this.http.get<User>("/user/get-current-user");
  }

  public getUsers() {
    return this.http.get<User[]>("/user/get-users")
  }

  public getUser(id: number) {
    return this.http.get<User>(`/user/get-user/${id}`)
  }

  public delete(id: number) {
    return this.http.post("Registration/DeleteClientUsers", {Id: id});
  }

  public getNamedSurveyorCount() {
    return this.http.get<number>('user/named-surveyor-count');
  }

  public save(model: LegacySaveUser) {
    return this.http.post<any>('Registration/SaveAddEditClientUser', model);
  }

  public changePassword(user: User) {
    return this.http.post('Account/ResetPassword', {username: user.username});
  }
}
