import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AccountDetails} from "../../models/account-details.model";
import {CompanyService} from "../../services/company.service";
import {LoadingService} from "../../services/loading.service";
import {AccountProfessionalBodyResult} from "../../models/account-professional-body-result.model";
import {MatListModule} from "@angular/material/list";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {spAnimations} from "../../helper/animation.helper";

@Component({
  selector: 'survpal-account-details',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    MatListModule,
    SpButtonComponent
  ],
  templateUrl: './account-details.component.html',
  styleUrl: './account-details.component.scss',
  animations: spAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountDetailsComponent implements OnInit {

  public model!: AccountDetails;
  public groups!: AccountProfessionalBodyResult[];
  public formGroup!: FormGroup

  constructor(private cdr: ChangeDetectorRef, private loadingService: LoadingService, private formBuilder: FormBuilder, private companyService: CompanyService, private snackBar: MatSnackBar) {
    this.loadingService.start();
  }

  ngOnInit() {
    this.companyService.loadDetails().subscribe(x => {
      this.model = x.data;
      this.groups = x.groups;
      this.buildForm();
      this.loadingService.stop();
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  buildForm() {

    let fields = {
      company_name: [this.model.CompanyName || '', Validators.required],
      address_line_1: [this.model.AddressLine1 || '', Validators.required],
      address_line_2: [this.model.AddressLine2 || ''],
      city: [this.model.City || '', [Validators.maxLength(50)]],
      county: [this.model.County || '', [Validators.maxLength(50)]],
      post_code: [this.model.PostCode || '', [Validators.maxLength(10), Validators.pattern(/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/)]],
      contact_number: [this.model.Phone || '', [Validators.maxLength(50)]],
    };

    this.formGroup = this.formBuilder.group(fields);
  }

  get companyNameField() {
    return this.formGroup.get('company_name');
  }

  get streetNameField() {
    return this.formGroup.get('street_name');
  }

  get houseNoField() {
    return this.formGroup.get('house_no');
  }

  get houseNameField() {
    return this.formGroup.get('house_name');
  }

  get cityField() {
    return this.formGroup.get('city');
  }

  get countyField() {
    return this.formGroup.get('county');
  }

  get postcodeField() {
    return this.formGroup.get('post_code');
  }

  get contactNumberField() {
    return this.formGroup.get('contact_number');
  }

  save() {

    this.model.CompanyName = this.formGroup.get('company_name')?.value;
    this.model.AddressLine1 = this.formGroup.get('address_line_1')?.value;
    this.model.AddressLine2 = this.formGroup.get('address_line_2')?.value;
    this.model.City = this.formGroup.get('city')?.value;
    this.model.County = this.formGroup.get('county')?.value;
    this.model.PostCode = this.formGroup.get('post_code')?.value;
    this.model.Phone = this.formGroup.get('contact_number')?.value;

    this.loadingService.start();
    this.companyService.saveDetails(this.model).subscribe({
      next: e => {
        this.loadingService.stop();
        this.snackBar.open("Your company account details have successfully been updated.", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
      },
      error: e => {
        this.loadingService.stop();
        this.snackBar.open("Something went wrong when trying to save company details, please try again", '', {
          duration: 5000,
          panelClass: 'snackbar'
        });
      }
    })
  }
}
