<div class="sp-p-4 sp-w-full">
  <div class="sp-text-3xl sp-mb-3 sp-text-sp-blue">{{ data.title }}</div>
  <mat-divider></mat-divider>
  <div class="sp-w-full sp-mt-4">
    @if (loaded) {
      <form class="sp-w-full" [formGroup]="formGroup">
        <div class="sp-flex sp-flex-row sp-mb-4 sp-w-full">
          <div class="sp-w-full">
            <div class="sp-text-2xl sp-mb-3 sp-text-sp-green ng-tns-c1914664829-3">Reset Password</div>
            <div class="sp-w-full sp-mb-2">
              <div class="sp-w-full sp-mb-2">
                <mat-form-field class="sp-w-full" appearance="outline">
                  <mat-label>
                    Current Password
                  </mat-label>
                  <input formControlName="old_password" type="password" matInput>
                  <mat-hint class="animate__animated animate__fadeIn"
                            *ngIf="oldPasswordField?.invalid && (oldPasswordField?.dirty || oldPasswordField?.touched)">
                    <small class="small animated fadeIn faster red d-block mt-2 mb-2"
                           *ngIf="oldPasswordField?.errors?.['required']">
                      Current password is required.
                    </small>
                  </mat-hint>
                </mat-form-field>
              </div>
              <div class="sp-w-full sp-mb-2">
                <mat-form-field class="sp-w-full" appearance="outline">
                  <mat-label>
                    Password
                  </mat-label>
                  <input formControlName="password" type="password" matInput>
                </mat-form-field>
                <ng-container *ngIf="passwordField?.invalid && (passwordField?.dirty || passwordField?.touched)">
                  <div class="sp-mb-4">
                    <small *ngIf="passwordField?.errors?.['required']" class="sp-block small animate__animated animate__fadeIn red d-block mt-1 mb-2">
                      Password is required. <br>
                    </small>
                    <ng-container *ngIf="passwordField.errors?.['passwordStrength']">
                      <small class="sp-block small animate__animated animate__fadeIn red d-block mt-1 mb-2">
                        The password must be a strong password, consisting of at least: <br>
                      </small>
                      <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                        One UPPERCASE character <i [ngClass]="getUpperCaseCheckClass(passwordField.value)"></i>
                      </small>
                      <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                        One lowercase character <i [ngClass]="getLowerCaseCheckClass(passwordField.value)"></i>
                      </small>
                      <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                        One number character <i [ngClass]="getNumberCaseCheckClass(passwordField.value)"></i>
                      </small>
                      <small class="sp-block small animate__animated animate__fadeIn grey d-block my-1">
                        One special character <i [ngClass]="getSpecialCaseCheckClass(passwordField.value)"></i>
                      </small>
                    </ng-container>
                    <small class="small animate__animated animate__fadeIn red d-block mt-2 mb-2"
                           *ngIf="passwordField.errors?.['maxlength']">
                      Password cannot be greater
                      than {{ passwordField?.errors ? passwordField?.errors?.['maxlength'].requiredLength : 0 }}
                      characters,
                      (Current
                      length: {{ passwordField.errors ? passwordField?.errors?.['maxlength'].actualLength : 0 }})
                    </small>
                    <small class="small animate__animated animate__fadeIn red d-block mt-2 mb-2"
                           *ngIf="passwordField.errors?.['minlength']">
                      Password cannot be less
                      than {{ passwordField.errors ? passwordField.errors?.['minlength'].requiredLength : 0 }}
                      characters,
                      (Current
                      length: {{ passwordField.errors ? passwordField.errors?.['minlength'].actualLength : 0 }})
                    </small>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="sp-w-full sp-mb-2">
              <mat-form-field class="sp-w-full" appearance="outline">
                <mat-label>
                  Confirm Password
                </mat-label>
                <input formControlName="confirm_password" type="password" class="sp-h-[42px]" matInput>
                <mat-hint class="animate__animated animate__fadeIn" *ngIf="password2Field?.errors?.['passwordMatch']">
                  <small class="small red d-block mt-2 mb-2">
                    Confirm password field must match the previously given password field
                  </small>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="sp-flex sp-flex-row sp-items-center sp-justify-end">
          <sp-button class="sp-mr-2" icon="fa-solid fa-times" (sp-click)="cancel()" colour="red">
            Cancel
          </sp-button>
          <sp-button class="sp-ml-2" icon="fa-solid fa-check" (sp-click)="save()" [disabled]="formGroup.invalid"
                     colour="green">
            Save
          </sp-button>
        </div>
      </form>
    }
  </div>
</div>
