import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {AddEditUserDialogDataModel} from "../../models/add-edit-user.model";
import {LoadingService} from "../../services/loading.service";
import {UserService} from "../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSelectModule} from "@angular/material/select";
import {UserRoles} from "../../enums/user-roles.enum";
import {MatListModule} from "@angular/material/list";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {BrandService} from "../../services/brand.service";
import {Branding} from "../../models/branding.model";
import {Observable} from "rxjs";
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {SpButtonComponent} from "../../components/shared/sp-button/sp-button.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {LegacySaveUser} from "../../models/legacy-save-user.model";
import {Password} from "../../models/password.model";
import {
  createPasswordStrengthValidator,
  getLowerCaseCheckClass, getNumberCaseCheckClass, getSpecialCaseCheckClass,
  getUpperCaseCheckClass
} from "../../helper/password.helper";

@Component({
  selector: 'survpal-add-edit-user',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatListModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatIconModule,
    SpButtonComponent,
    MatTooltipModule,
    NgIf,
    NgClass
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {

  public model: Password = new Password();
  public formGroup!: FormGroup;
  public loaded = false;

  protected readonly UserRoles = UserRoles;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddEditUserDialogDataModel,
    private formBuilder: FormBuilder, private loadingService: LoadingService, private userService: UserService,
    private cdr: ChangeDetectorRef, private snackBar: MatSnackBar, private brandService: BrandService) {
  }

  ngOnInit() {
    this.buildForm();
    this.loaded = true;
    this.cdr.detectChanges();
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  buildForm() {
    let fields = {
      old_password: [this.model.old_password || '', Validators.required],
      password: [this.model.new_password || '', [createPasswordStrengthValidator(), Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      confirm_password: [this.model.confirm_password || '', [this.passwordMatch()]],
    };

    this.formGroup = this.formBuilder.group(fields);
  }

  passwordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!this.formGroup) {
        return null;
      }
      return value != this.formGroup.get('password')?.value ? {passwordMatch: true} : null;
    }
  }

  get oldPasswordField() {
    return this.formGroup.get('old_password');
  }

  get passwordField() {
    return this.formGroup.get('password');
  }

  get password2Field() {
    return this.formGroup.get('confirm_password');
  }

  save() {
    this.dialogRef.close({
      old_password: this.formGroup.get('old_password')?.value,
      password: this.formGroup.get('password')?.value
    })
  }

  protected readonly getUpperCaseCheckClass = getUpperCaseCheckClass;
  protected readonly getLowerCaseCheckClass = getLowerCaseCheckClass;
  protected readonly getNumberCaseCheckClass = getNumberCaseCheckClass;
  protected readonly getSpecialCaseCheckClass = getSpecialCaseCheckClass;
}

