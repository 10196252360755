import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {UserService} from "../../../services/user.service";
import {LoadingService} from "../../../services/loading.service";
import {User} from "../../../models/user.model";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {LoginService} from "../../../services/login.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {NgClass} from "@angular/common";
import {JobService} from "../../../services/job.service";
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay";
import {JobSearch} from "../../../models/job-search.model";
import {MatListModule} from "@angular/material/list";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, Subscription, switchMap} from "rxjs";
import {MatRippleModule} from "@angular/material/core";
import {RoleHelper} from "../../../helper/role.helper";
import {MatBadgeModule} from "@angular/material/badge";
import {CompanyService} from "../../../services/company.service";
import {MatTooltipModule} from "@angular/material/tooltip";
import {getRoleName} from "../../../enums/user-roles.enum";

@Component({
  selector: 'survpal-application-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    RouterLink,
    RouterLinkActive,
    NgClass,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    MatListModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatBadgeModule,
    MatTooltipModule
  ],
  templateUrl: './application-header.component.html',
  styleUrl: './application-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationHeaderComponent implements OnInit, OnDestroy {

  public user: User = this.userService.currentUser.value;

  public jobResults: JobSearch[] = [];
  public resultsOpen = false;

  public searchControl = new FormControl();

  @ViewChild('rla1')
  public rla1?: RouterLinkActive;

  public subscriptions: Subscription[] = [];
  public credits: number = 1;
  public findingJobs = false;

  constructor(private cdr: ChangeDetectorRef, private companyService: CompanyService, private userService: UserService, private snackBar: MatSnackBar, private loadingService: LoadingService, private router: Router, private loginService: LoginService, private jobService: JobService) {

  }

  ngOnInit() {
    this.userService.currentUser.subscribe(x => {
      this.user = x;
    });

    this.searchControl.valueChanges.pipe(debounceTime(1000), switchMap(
      value => {
        this.findingJobs = true;
        this.cdr.detectChanges();
        return this.jobService.findJobs(value)
      }
    )).subscribe({
      next: (values) => {
        this.findingJobs = false;
        this.jobResults = values;
        this.resultsOpen = true;
        if(!this.jobResults.length) {
          this.snackBar.open("No jobs have been found for your search term.", '', {
            duration: 5000,
            panelClass: 'snackbar'
          });
        }
        this.cdr.detectChanges();
      },
      error: e => this.#findError()
    });

    this.companyService.creditCount.subscribe(x => {
      this.credits = x;
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  logout() {
    this.loginService.logout().subscribe({
      next: () => {
        this.userService.currentUser.next(new User());
        this.router.navigate(['/'])
      },
      error: e => this.#genericError()
    })
  }

  #genericError() {
    this.loadingService.stop();
    this.snackBar.open("An error occurred whilst trying to log you out, please try again.", '', {
      duration: 5000,
      panelClass: 'snackbar'
    });
  }

  #findError() {
    this.findingJobs = false;
    this.loadingService.stop();
    this.snackBar.open("An error occurred whilst trying to find a job, please try again.", '', {
      duration: 5000,
      panelClass: 'snackbar'
    });
    this.cdr.detectChanges();
  }

  closeResults() {
    this.resultsOpen = false;
  }

  goToJob(item: JobSearch) {
    this.resultsOpen = false;
    this.loadingService.start();
    this.cdr.detectChanges();
    window.location.href = `/job/Edit/?ID=${item.NoticeID}&AOID=${item.AOID}`;
  }

  isAdminActive() {
    return this.rla1?.isActive;
  }

  getRoleName(){
    return getRoleName(this.user.permission)
  }

  protected readonly RoleHelper = RoleHelper;
}
